import assignedComponent from "./custom-forms/assigned-component";
import emailComponent from "./custom-forms/email-component";
import startDateComponent from "./custom-forms/start-date";
import endDateComponent from "./custom-forms/end-date";
import nextActionDateComponent from "./custom-forms/next-action-date";
import statusComponent from "./custom-forms/status";
import observersComponent from "./custom-forms/observers";
import attachmentsComponent from "./custom-forms/attachments";
import descriptionComponent from "./custom-forms/description";
import nameComponent from "./custom-forms/name";
import zipcodeComponent from "./custom-forms/zip-code";
import jobTitleComponent from "./custom-forms/job-title";
import cityComponent from "./custom-forms/city";
import faxNumberComponent from "./custom-forms/fax-number";
import departmentComponent from "./custom-forms/department";
import userlistComponent from "./custom-forms/user-list";
import parenttaskComponent from "./custom-forms/parent-task";
import teamsComponent from "./custom-forms/teams"

export default [
  {
    component: assignedComponent, //actual json components
    name: "assignedComponent", //name of the component on left menu
    title: "Assigned", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },
  {
    component: emailComponent, //actual json components
    name: "emailComponent", //name of the component on left menu
    title: "Email", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },
  {
    component: startDateComponent, //actual json components
    name: "startDate1", //name of the component on left menu
    title: "Start Date", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },
  {
    component: endDateComponent, //actual json components
    name: "endDate", //name of the component on left menu
    title: "End Date", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },
  {
    component: nextActionDateComponent, //actual json components
    name: "nextActionDate", //name of the component on left menu
    title: "Next Action Date", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },
  {
    component: statusComponent, //actual json components
    name: "status", //name of the component on left menu
    title: "Status", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },
  {
    component: observersComponent, //actual json components
    name: "observers", //name of the component on left menu
    title: "Observers", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },
  {
    component: attachmentsComponent, //actual json components
    name: "attachments", //name of the component on left menu
    title: "Attachments", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },
  {
    component: descriptionComponent, //actual json components
    name: "description", //name of the component on left menu
    title: "Description", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },
  {
    component: nameComponent, //actual json components
    name: "name", //name of the component on left menu
    title: "Name", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  }, {
    component: zipcodeComponent, //actual json components
    name: "zipcode", //name of the component on left menu
    title: "Zip Code", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },{
    component: jobTitleComponent, //actual json components
    name: "jobtitle", //name of the component on left menu
    title: "Job Title", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },{
    component: teamsComponent, //actual json components
    name: "teams", //name of the component on left menu
    title: "Groups", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  }
  ,{
    component: cityComponent, //actual json components
    name: "city", //name of the component on left menu
    title: "City", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },{
    component: faxNumberComponent, //actual json components
    name: "faxnumber", //name of the component on left menu
    title: "Fax Number", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },
  {
    component: departmentComponent, //actual json components
    name: "department", //name of the component on left menu
    title: "Department", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  }
  ,{
    component: userlistComponent, //actual json components
    name: "userlist", //name of the component on left menu
    title: "userlist", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },{
    component: parenttaskComponent, //actual json components
    name: "userlist", //name of the component on left menu
    title: "Parent Task", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },
  
];
