import React from "react";
import "./RadioButton.scss";

const RadioButton = ({ value, text, disabled, id, onChanged }) => {
  return (
    <div className="RadioButtonStyle">
      <input
        onChange={onChanged}
        type="radio"
        id={id}
        value={value}
        disabled={disabled}
      />
      <label htmlFor={id}>{text}</label>
    </div>
  );
};

export default RadioButton;
