import React from "react";
import "./ToggleButton.scss";

const ToggleButton = ({ id, text, disabled }) => {
  return (
    <div className="toggleContainer">
      <div className="toggle">
        <input type="checkbox" disabled={disabled} name="" id={id} />
        <span className="slider"></span>
      </div>
      <p className="text">{text}</p>
    </div>
  );
};

export default ToggleButton;
