import React, { useEffect, useState } from "react";
import { Breadcrumb } from "@progress/kendo-react-layout";

const BreadCrumbs = ({ core, appNavigationDiv }) => {
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const loader = core.make("oxzion/splash");

  useEffect(() => {
    const element = document.getElementById(appNavigationDiv);
    const customEvents = {
      generateBreadCrumbs,
      stepDownOneBreadCrumb,
      clearBreadCrumbs,
    };
    for (const key in customEvents) {
      element.addEventListener(key, customEvents[key], false);
    }
  }, []);

  const generateBreadCrumbs = (data) => { //data of the incoming page
    setBreadCrumbs((prev) => {
      let tempBreadcrumbs = [];
      let removeElement = false;
      data.detail.data.forEach((data, index) => {
        //checking if there are any repeats in  breadcrumbs
        const isUnique = prev.every((breadCrumb) => {
          if (breadCrumb.type === "page") return breadCrumb.id !== `${data.pageId}_${index}`;
          else if (breadCrumb.type === "dashboard") return breadCrumb.id !== data.data?.uuid;
        });
        if (!isUnique) return; //not adding dumplicates
        if (data.type === "page" && isUnique) {
          //checks if the page that is being added has already been added
          let removeElement = prev.some((breadCrumb) => breadCrumb?.prevPageId == `${data.pageId}_${index}`);
          tempBreadcrumbs.push({
            id: `${data.pageId}_${index}`,
            text: data.title,
            removeElement,
            type: data.type,
            disabled: false,
          });
        } else if (data.type === "dashboard" && isUnique) {
          //dashboard loads inside a page, hence causing 2 breadcrumbs (one for page,one for dashboard)
          //hence checking if prev is page then removing the breadcrumb
          removeElement = prev[prev.length - 1]?.type === "page";
          tempBreadcrumbs.push({
            id: data.data.uuid,
            text: data.data.name,
            prevPageId: prev[prev.length - 1]?.id, //to keep a record of the page the dashboard loads in
            disabled: false,
            type: data.type,
            drilldownDashboardFilter: data.drilldownDashboardFilter, //storing filters cause some of them get erased when stepping down
          });
        }
      });
      let tempArrNew = [...prev, ...tempBreadcrumbs];
      if (removeElement) tempArrNew = tempArrNew.slice(0, -2).concat(tempArrNew.slice(-1));
      tempArrNew = tempArrNew.filter((breadCrumb) => !breadCrumb.hasOwnProperty("removeElement") || breadCrumb.removeElement !== true);
      return tempArrNew;
    });
    return breadCrumbs;
  };

  const stepDownOneBreadCrumb = () => {
    //! stepdown breaks only when listview drills down into an oi
    //using prev value cause of scope issues
    setBreadCrumbs((prev) => {
      if (prev?.length > 1) {
        return prev.slice(0, -1);
      } else {
        //prevents breadcrumbs from breaking the app by returning an array to kendobreadcrumbs
        return prev;
      }
    });
  };

  const clearBreadCrumbs = () => {
    setBreadCrumbs([]);
  };

  const handleItemSelect = (e) => {
    const indexOfCurrentPage = breadCrumbs.length;
    const breadCrumbClicked = breadCrumbs.findIndex((breadcrumb) => breadcrumb.id === e.id) + 1;
    const currentBreadCrumb = breadCrumbs[breadCrumbClicked - 1];
    const noOfPagesToStepDown = indexOfCurrentPage - breadCrumbClicked;

    for (let i = 0; i < noOfPagesToStepDown; i++) {
      document
        .getElementById(appNavigationDiv)
        .dispatchEvent(new CustomEvent("stepDownPage", { detail: { dashboardFilter: currentBreadCrumb.drilldownDashboardFilter } }));
    }
  };

  return (
    <div className="BreadCrumbContainer">
      <Breadcrumb data={breadCrumbs} onItemSelect={handleItemSelect} />
    </div>
  );
};

export default BreadCrumbs;
