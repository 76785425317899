import React from "react";
import ReactDOM from "react-dom/client";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { filterBy, orderBy, process } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import GridActions from "./GridActions";
import FormRender from "../App/FormRender";
import Requests from "../../Requests";
import Swal from "sweetalert2";
import { GridDetailRow } from "@progress/kendo-react-grid";

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);
export default class EOXGrid extends React.Component {
  constructor(props) {
    super(props);
    this.excelExporter = null;
    this.allData = this.props.data ? this.props.data : [];
    this.filteredData = null;
    let configuration = this.props.configuration;
    this.isDrillDownTable = this.props.isDrillDownTable;
    this.resizable = configuration ? (configuration.resizable ? configuration.resizable : false) : false;
    this.filterable = configuration ? (configuration.filterable ? configuration.filterable : false) : false;
    this.groupable = configuration ? (configuration.groupable ? configuration.groupable : false) : false;
    this.reorderable = configuration ? (configuration.reorderable ? configuration.reorderable : false) : false;
    this.height = configuration ? (configuration.height ? configuration.height : "100%") : "100%";
    this.subRoute = configuration ? configuration.subRoute : null;
    this.width = configuration ? (configuration.width ? configuration.width : "100%") : "100%";
    this.columnConfig = configuration ? (configuration.column ? configuration.column : []) : [];
    this.sortable = configuration ? (configuration.sort ? true : false) : false;
    this.pagerConfig = configuration ? (configuration.pageable ? { pageable: configuration.pageable } : {}) : {};
    this.pageSize = configuration ? (configuration.pageSize ? configuration.pageSize : 10) : 10;
    this.isReactComponent = configuration ? (configuration.isReactComponent ? configuration.isReactComponent : false) : false;
    //need to pass these in org config
    let oxzionMeta = configuration ? (configuration["oxzion-meta"] ? configuration["oxzion-meta"] : null) : null;
    this.exportToExcel = oxzionMeta ? (oxzionMeta["exportToExcel"] ? oxzionMeta["exportToExcel"] : false) : false;
    this.core = this.props.core;
    this.helper = this.core.make("oxzion/link");
    this.actionItems = this.props.actionItems;
    this.api = this.props.api;
    this.permission = this.props.permission;
    this.editForm = this.props.editForm;
    this.businessRelationshipForm = this.props.businessRelationshipForm;
    this.editApi = this.props.editApi;
    this.createApi = this.props.createApi;
    this.deleteApi = this.props.deleteApi;
    this.selectedOrg = this.props.selectedOrg;
    this.addConfig = this.props.addConfig;
    this.noCreateAction = this.props.noCreateAction ? this.props.noCreateAction : false;
    this.baseUrl = this.core.config("wrapper.url");
    this.gridId = Date.now();
    this.detailGrid = Date.now() + 1;
    this.viewForm = this.props.viewForm ? this.props.viewForm : "";
    this.viewApi = this.props.viewApi ? this.props.viewApi : false;
    this.EOXGridStyle = this.props.style ? this.props.style : null;
    this.state = {
      editCreateRole: false,
      editRecordData: { data: null, index: null },
      filter: null,
      props: this.props,
      pagination: {
        skip: 0,
        take: this.pageSize,
      },
      sort: configuration ? (configuration.sort ? configuration.sort : null) : null,
      group: null,
      displayedData: { data: this.props.data },
      isLoading: props.isLoading,
      exportFilterData: [],
      dataState: {},
    };

    let beginWith = configuration ? configuration.beginWith : null;
    if (beginWith) {
      let page = beginWith.page;
      if (page) {
        this.state.pagination.skip = page.skip ? page.skip : 0;
        this.state.pagination.take = page.take ? page.size : 10;
      }
      this.state.sort = beginWith.sort ? beginWith.sort : null;
      this.state.group = beginWith.group ? beginWith.group : null;
      this.state.filter = beginWith.filter ? beginWith.filter : null;
    }
    this.create = this.create.bind(this);
  }

  //updating the data
  updateDisplayData = () => {
    this.props.dataStateChanged({ dataState: { skip: this.props.skip, take: this.pageSize, filter: this.state.dataState.filter, group: null, sort: null }, refresh: true });
  };

  callCreateApi = async (formData, form) => {
    await this.props.appendAttachments?.(formData, form);
    Requests.createFormPushData(this.core, this.createApi, this.props.getCustomPayload?.(formData, null, form) || formData, this.props.createCrudType).then((response) => {
      if (response.status === "success") {
        Swal.fire({
          icon: "success",
          title: response.status,
          showConfirmButton: true,
        });
        this.updateDisplayData({ crudType: "CREATE", data: response.data });
        this.create(null);
        return;
      } else if (response.status === "error") {
        if (response.message.includes("reactivate")) {
          Swal.fire({
            icon: "info",
            title: response.message,
            showConfirmButton: true,
            showCancelButton: true,
          }).then((res) => {
            if (res.isConfirmed) {
              formData["reactivate"] = 1;
              this.handleCreateSubmit(formData, form);
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: response.message,
            showConfirmButton: true,
          });
        }
        this.create(null);
      }
  });
  };

  async handleCreateSubmit(formData, form) {
    if (formData == "cancel") {
      this.create(null);
      return;
    } else if (formData) {
      this.callCreateApi(formData, form);
    } else {
      this.create(null);
    }
  }

  create = async (form, createFlag) => {
    let gridsId = document.getElementsByClassName("eox-grids")[0].parentNode.id;
    if (createFlag) {
      document.getElementById(gridsId).classList.add("display-none");
      document.getElementById("titlebar-admin").style.zIndex = "10";
      document.getElementById("titlebar-admin").style.display = "none";
      if (this.isReactComponent) {
        // document.getElementById("eox-grid").style.marginTop = "-35px";
      } else {
        // document.getElementById("eox-grid").style.marginTop = "-40px";
      }
    } else {
      document.getElementById(gridsId).classList.remove("display-none");
      document.getElementById("titlebar-admin").style.display = "flex";
      // document.getElementById("eox-grid").style.marginTop = "-35px";
    }
    let data = {};
    if (this.props.prepareCreateFormData) {
      data = await this.props.prepareCreateFormData();
    }

    const RoleFormComponent = this.isReactComponent ? this.editForm : null;
    let apiSplit = this.api;
    let changedAccountId = apiSplit.split("/")[1];
    if (this.isReactComponent) {
      const root = ReactDOM.createRoot(document.getElementById("eox-grid-form")) 
      root.render(
        <RoleFormComponent
          args={this.core}
          formAction={"post"}
          createApi={this.createApi}
          selectedOrg={this.props.selectedOrg}
          reactRoot = {root}
          // diableField="false"
          gridsId={gridsId}
          isReactComponent={this.isReactComponent}
          onUpdate={this.updateDisplayData}
        />,
      )
        ? (document.getElementById("eox-grid-form").style.overflow = "scroll")
        : (document.getElementById("eox-grid-form").style.overflow = "auto");
    } else {
      const root = ReactDOM.createRoot(document.getElementById("eox-grid-form")) 
      root.render(
        createFlag ? (
          <div
            style={{
              position: "absolute",
              left: "0",
              top: this.api === "account" ? "0px" : "-15px",
              width: "100%",
              height: "100%",
              zIndex: "100",
            }}
          >
            <FormRender
              key={"abc"}
              core={this.core}
              data={{ accountId: changedAccountId, createFlag: true }}
              updateFormData={true}
              getAttachment={true}
              reactRoot = {root}
              postSubmitCallback={(formData, form) => {
                this.handleCreateSubmit(formData, form);
              }}
              {...data}
              content={form}
              uniqueAttachments={this.props.uniqueAttachments || false}
              // appId={data.uuid}
              // route= {this.api}
            />
          </div>
        ) : null,
      )
        ? (document.getElementById("eox-grid-form").style.overflow = "scroll")
        : (document.getElementById("eox-grid-form").style.overflow = "auto");
    }
  };

  saveAsExcel = () => {
    let filterData;
    filterData = Object.keys(this.state.exportFilterData).length > 0 ? this.state.exportFilterData : this.allData;
    this.excelExporter.save(filterData);
  };

  parseData = () => {
    let fieldDataTypeMap = new Map();
    for (const config of this.columnConfig) {
      if (config?.dataType) {
        fieldDataTypeMap.set(config["field"], config["dataType"]);
      }
    }
    for (let dataItem of this.allData) {
      for (let [field, dataType] of fieldDataTypeMap) {
        switch (dataType) {
          case "date":
            dataItem[field] = new Date(dataItem[field]);
            break;
          default:
            throw `Column data type ${dataType} is not parsed. Add parser to parse it.`;
        }
      }
    }
  };

  // getRandomInt(min, max) {
  //   min = Math.ceil(min);
  //   max = Math.floor(max);
  //   return Math.floor(Math.random() * (max - min + 1)) + min;
  // }

  prepareData = (refilter) => {
    if (this.allData) {
      this.allData.map((data) => {
        //trimmimg time from date in order for date filter to work
        data.date ? data.date.setHours(0, 0, 0, 0) : null;
      });
    }
    if (this.state.sort) {
      this.allData = orderBy(this.allData, this.state.sort);
    }
    if (!this.filteredData || refilter) {
      let filter = this.state.filter;
      this.filteredData = filter ? filterBy(this.allData, filter) : this.allData;
    }
    let pagination = this.state.pagination;
    let displayedData = process(this.filteredData, {
      take: pagination.take,
      skip: this.state.filter ? (refilter ? 0 : pagination.skip) : pagination.skip,
      group: this.state.group,
    });
    this.setState({
      displayedData: displayedData,
    });
  };

  getFilteredRowCount = () => {
    return this.filteredData ? this.filteredData.length : 0;
  };

  componentDidMount() {
    const customEventHandlers = {
      create: (e) => {
        var that = this;
        that.create(e.detail.editForm, e.detail.bool);
      },
    };
    for (const key in customEventHandlers) {
      document.getElementById("eox-grid").addEventListener(key, customEventHandlers[key], false);
    }
    if (this.props.expandableApi) {
      this.props.expandableApi((childGridResponse) => {
        this.allData = childGridResponse;
        // this.setState({displayedData :childGridResponse, isLoading : props.isLoading})
        this.parseData();
        this.prepareData(true);
      });
      return;
    }
    this.parseData();
    // this.prepareData(true);
  }

  shouldComponentUpdate(prevProps, props) {
    prevProps.api !== this.api ? (this.api = prevProps.api) : "";
    prevProps.deleteApi !== this.deleteApi ? (this.deleteApi = prevProps.deleteApi) : "";
    prevProps.createApi !== this.createApi ? (this.createApi = prevProps.createApi) : "";
    prevProps.editApi !== this.editApi ? (this.editApi = prevProps.editApi) : "";
    return true;
  }

  componentWillReceiveProps(props) {
    if (props.isLoading !== this.state.displayedData?.isLoading) {
      this.setState({ displayedData: props.data, isLoading: props.isLoading });
    }
  }

  gridGroupChanged = (e) => {
    this.setState(
      {
        group: e.group,
      },
      () => {
        this.prepareData(false);
      },
    );
  };

  handleEditCreateFormShow = (show) => {
    this.setState({editCreateRole: show })
  }

  setSelectedFormData = (data, index, show) => {
    this.setState({ editRecordData: { data, index }, editCreateRole: show });
    this.props.handleShowTitleBar?.(false)
  }

  expandChange = (event) => {
    this.setState((prevState) => {
      let newGridData = { ...prevState.displayedData };
      newGridData.data = newGridData?.data?.map((i) => {
        i.expanded = event.dataItem.uuid == i.uuid ? !i.expanded : false;
        return i;
      });
      return { displayedData: newGridData };
    });
  };

  render() {
    let gridTag = (
      <div id="eox-grid" class="eoxGridsList" style={this.EOXGridStyle ? this.EOXGridStyle : { position: "relative", marginTop: "0px" }}>
        <div id="eox-grid-form"></div>
        <div id={this.noCreateAction ? this.detailGrid : this.gridId}>
          {this.state.editCreateRole === 'create' && this.isReactComponent && (
            <this.editForm
              args={this.core}
              formAction={"post"}
              createApi={this.createApi}
              selectedOrg={this.props.selectedOrg}
              // diableField="false"
              isReactComponent={this.isReactComponent}
              onUpdate={this.updateDisplayData}
              handleEditCreateFormShow={this.handleEditCreateFormShow}
              handleShowTitleBar={this.props.handleShowTitleBar || ""}
            />
          )}
          {this.state.editCreateRole === 'edit'  && this.isReactComponent && (
            <this.editForm
              args={this.core}
              dataItem={this.state.editRecordData.data}
              formAction={"put"}
              editApi={this.editApi}
              selectedOrg={this.props.selectedOrg}
              // edit={this.edit(null)}
              isReactComponent={this.isReactComponent}
              index={this.state.editRecordData.index}
              onUpdate={this.updateDisplayData}
              handleEditCreateFormShow={this.handleEditCreateFormShow}
              handleShowTitleBar={this.props.handleShowTitleBar || ""}
            />
          )}
          {!this.state.editCreateRole && (
            <Grid
              style={{ height: this.height, width: this.width }}
              className={"eox-grids"}
              data={this.state.displayedData}
              resizable={this.resizable}
              reorderable={this.reorderable}
              detail={
                this.props.rowTemplate
                  ? (dataItem) => (
                      <div style={{ marginTop: "45px" }}>
                        <DetailComponent rowTemplate={this.props.rowTemplate} dataItem={dataItem.dataItem} />
                      </div>
                    )
                  : undefined
              }
              filterable={this.filterable}
              filter={this.state.filter}
              filterOperators={{
                text: [
                  { text: "grid.filterContainsOperator", operator: "contains" },
                  { text: "grid.filterStartsWithOperator", operator: "startswith" },
                  { text: "grid.filterEqOperator", operator: "eq" },
                  // { text: "grid.filterEndsWithOperator", operator: "endswith" },
                  // { text: "grid.filterNotContainsOperator", operator: "doesnotcontain" },
                ],
                numeric: [
                  { text: "grid.filterEqOperator", operator: "eq" },
                  { text: "grid.filterGteOperator", operator: "gte" },
                  { text: "grid.filterGtOperator", operator: "gt" },
                  { text: "grid.filterLteOperator", operator: "lte" },
                  { text: "grid.filterLtOperator", operator: "lt" },
                ],
                date: [
                  { text: "grid.filterEqOperator", operator: "eq" },
                  { text: "grid.filterGteOperator", operator: "gte" },
                  { text: "grid.filterGtOperator", operator: "gt" },
                  { text: "grid.filterLteOperator", operator: "lte" },
                  { text: "grid.filterLtOperator", operator: "lt" },
                ],
                boolean: [{ text: "grid.filterEqOperator", operator: "eq" }],
              }}
              pageSize={this.pageSize}
              {...this.pagerConfig} //Sets grid "pageable" property
              total={this.state.displayedData.total}
              skip={this.props.skip}
              take={this.state.pagination.take}
              sortable={this.sortable}
              sort={this.state.sort}
              onExpandChange={this.expandChange}
              onDataStateChange={(e) => {
                if (e?.dataState?.filter?.filters?.find((v) => !v.field)) return;
                if (e?.dataState?.filter?.filters?.find((o) => o.value === "")) return;
                this.setState({ dataState: e.dataState });
                this.props.dataStateChanged(e);
              }}
              expandField="expanded"
              {...this.state.dataState}
            >
              {this.columnConfig.map((columns) =>
                columns.title == "Image" || columns.title == "Banner" ? (
                  <GridColumn
                    key={columns.field}
                    title={columns.title}
                    filterable={columns.filterable}
                    width={columns.width}
                    cell={(props) => <LogoCell {...props} myProp={this.props} title={columns.title} url={this.baseUrl} />}
                  ></GridColumn>
                ) : (
                  <GridColumn
                    key={columns.field}
                    field={columns.field}
                    title={columns.title}
                    filterable={columns.filterable}
                    width={columns.width}
                  ></GridColumn>
                ),
              )}
              <GridColumn
                title="Actions"
                filterable={false}
                cell={(row) => {
                  return  <GridActions
                  row={row?.dataItem ?? null}
                  dataItem={this.state.displayedData}
                  core={this.props.core}
                  api={this.api}
                  actionItems={this.actionItems}
                  onUpdate={this.updateDisplayData}
                  permission={this.permission}
                  editForm={this.editForm}
                  businessRelationshipForm={this.businessRelationshipForm}
                  viewForm={this.viewForm}
                  viewApi={this.viewApi}
                  editApi={this.editApi}
                  createApi={this.createApi}
                  deleteApi={this.deleteApi}
                  gridId={this.gridId}
                  addConfig={this.addConfig}
                  appendAttachments={this.props.appendAttachments}
                  fetchAttachments={this.props.fetchAttachments}
                  createCrudType={this.props.createCrudType}
                  getCustomPayload={this.props.getCustomPayload}
                  prepareFormData={this.props.prepareFormData}
                  isReactComponent={this.isReactComponent}
                  selectedOrg={this.props.selectedOrg}
                  setSelectedFormData={this.setSelectedFormData}
                  rolesPage={this.props.rolesPage || undefined}
                />
                }}
              ></GridColumn>
            </Grid>
          )}
        </div>
      </div>
    );

    return (
      <>
        {this.state.isLoading && loadingPanel}
        {this.exportToExcel && (
          <>
            <div
              // className="oxzion-widget-drilldown-excel-icon"
              onClick={this.saveAsExcel}
            >
              <i className="fa fa-file-excel fa-lg"></i>
            </div>
            <ExcelExport data={this.state.exportFilterData} ref={(exporter) => (this.excelExporter = exporter)} filterable>
              {gridTag}
            </ExcelExport>
          </>
        )}
        {!this.exportToExcel && gridTag}
      </>
    );
  }
}

class LogoCell extends React.Component {
  render() {
    // Image -Account & User
    if (this.props.title === "Image") {
      return this.props.dataItem.logo ? (
        <td>
          <img src={this.props.dataItem.logo + "?" + new Date()} alt="Logo" className="text-center circle gridBanner" />
        </td>
      ) : this.props.dataItem.icon ? (
        <td>
          <img src={this.props.dataItem.icon + "?" + new Date()} alt="Logo" className="text-center circle gridBanner" />
        </td>
      ) : (
        <td></td>
      );
    }
    // Banner - Announcement
    else if (this.props.title == "Banner") {
      if (this.props.dataItem.media_type == "image") {
        return this.props.dataItem.media ? (
          <td>
            <img src={this.props.url + "resource/" + this.props.dataItem.media + "?" + new Date()} alt="Logo" className="text-center circle gridBanner" />
            {/* {
              this.props.url +
                "resource/" +
                this.props.dataItem.media +
                "?" +
                new Date()
            } */}
          </td>
        ) : null;
      } else {
        return this.props.dataItem.media ? (
          <td>
            <video className="text-center circle gridBanner">
              <source src={this.props.url + "resource/" + this.props.dataItem.media + "?" + new Date()} type="video/mp4" />
            </video>
          </td>
        ) : null;
      }
    }
  }
}
class DetailComponent extends GridDetailRow {
  render() {
    const dataItem = this.props.dataItem;
    return <React.Fragment>{this.props.rowTemplate(dataItem)}</React.Fragment>;
  }
}
