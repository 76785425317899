import { Button } from "@progress/kendo-react-buttons";
import React, { Ref } from "react";
import { CoreBase, Pageable } from "../../interfaces";
import OX_Grid from "../OI/OX_Grid";
import '../../public/css/ckeditorStyle.css' //needed to render ckeditor styles

interface Props {
  core: CoreBase;
  config?: any;
  appId: string;
  fileId: string;
  cancel?: any;
}
class ActivityLog extends React.Component<Props, any> {
  private config: any;
  private core: CoreBase;
  private profileAdapter: any;
  private messageBox: any;
  private profile: any;
  private appId: string;
  private fileId: string;
  private filterable: boolean;
  private reorderable: boolean;
  private resizable: boolean;
  private sortable: boolean;
  private api: string;
  private pageable: Pageable;
  private parentGrid: Ref<any>;
  private childGrid: Ref<any>;
  constructor(props) {
    super(props);
    this.config = this.props.config;
    this.core = this.props.core;
    this.profileAdapter = this.core.make("oxzion/profile");
    this.messageBox = this.core.make("oxzion/messageDialog");
    this.profile = this.profileAdapter.get();
    this.appId = this.props.appId;
    this.fileId = this.props.fileId;
    this.filterable = false;
    this.reorderable = false;
    this.resizable = false;
    this.sortable = false;
    this.api = "app/" + this.appId + "/file/" + this.fileId + "/audit";

    var columnConfig = [
      {
        field: "version",
        title: "Version",
      },
      {
        field: "file_date_modified",
        title: "Performed On",
        filter: "date",
        filterFormat: "YYYY-MM-DD",
        cell: "<td>{formatDate(item.file_date_modified,'YYYY-MM-DD')}</td>",
      },
      {
        field: "modifiedUser",
        title: "Modified By",
      },
      {
        field: "action",
        title: "Action Performed",
        className: "text-capitalize"
      },
    ];

    this.pageable = { buttonCount: 3, pageSizes: [10, 20, 50] };
    this.state = {
      columnConfig: columnConfig,
      filter: [],
    };
    this.parentGrid = React.createRef();
    this.childGrid = React.createRef();
  }

  openModal(content, messageBox) {
    messageBox.show('', '', 'Close', false, '<div style="font-size:15px;" class="ck-content">' + content + '</div>', 'modal_viewer_css');
  }

  renderRow(e) {
    let buttonElement = (value) => {
      if (typeof value == 'number' || value instanceof Array) {
        return value;
      }
      let regex = /<img/g;
      let result = value.match(regex);
      var matchResult = true;
      if (!result) {
        matchResult = false;
      }
      if (value.split(' ').length > 50 || matchResult) {
        return (
          <Button
            onClick={() => this.openModal(value, this.messageBox)}
            className={"toolBarButton btn-primary dash-manager-buttons"}
          >
            <i className="fa fa-eye"></i>
          </Button>
        )
      }
      return <div className="ck-content" dangerouslySetInnerHTML={{__html: value}} />; //needed to render audit log text as html
    }
    var childColumnConfig = [
      {
        title: "Field",
        field: "text",
        editable: false
      },
      {
        title: "Previous Value",
        field: "initialValue",
        cell: (item) => buttonElement(item.initialValue)
      },
      {
        title: "Updated Value",
        field: "submittedValue",
        cell: (item) => buttonElement(item.submittedValue)
      },
    ];
    return (
      <div style={{minHeight: '100px'}}>
        <OX_Grid
          osjsCore={this.props.core}
          ref={this.childGrid}
          data={e?.fields || []}
          columnConfig={childColumnConfig}
          inlineEdit={false}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="activityLogWindow">
        <div
          id="loading-animation"
          className="blockUI blockMsg blockElement loadingdivcss hide"
        >
          <div className="loading-message ">
            <div className="block-spinner-bar">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>
        </div>
        {/* <div id="browser-no-support" className="hide">
        Your browser does not support this functionality! <br/> 
        Please use Google Chrome or Firefox.
        </div> */}
        <div className="logResults" style={{minHeight: '100px'}}>
          <OX_Grid
            osjsCore={this.core}
            filterable={this.filterable}
            data={this.api}
            reorderable={this.reorderable}
            expandable={true}
            rowTemplate={(e) => this.renderRow(e)}
            resizable={this.resizable}
            pageable={this.pageable}
            sortable={this.sortable}
            columnConfig={this.state.columnConfig}
          />
        </div>
      </div>
    );
  }
}
export default React.memo(ActivityLog);
