"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _nativePromiseOnly = _interopRequireDefault(require("native-promise-only"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var oxzionUploader = function oxzionUploader(formio) {
  return {
    title: 'OxzionUploader',
    name: 'oxzionUploader',
    uploadFile: function uploadFile(file, fileName, dir, progressCallback, url, options, fileKey, groupPermissions, groupId, abortCallback) {
      var reader = new FileReader();
      return new _nativePromiseOnly.default(function (resolve, reject) {
        let restClient=OSjs.make("oxzion/restClient");
        let response=restClient.request("v1",url, {file}, "fileupload").then(
          function(response){
            reader.onload = function (event) {
              resolve({
                storage: 'oxzionUploader',
                name: fileName,
                url: response.url,
                size: file.size,
                type: file.type
              });
            };
            reader.onerror = function () {
              return reject(_this);
            };
            reader.readAsDataURL(file);
          }
        
        );
       
      });
    },
    downloadFile: function downloadFile(file) {
      return _nativePromiseOnly.default.resolve(file);
    }
  };
};
oxzionUploader.title = 'Upload File';
var _default = oxzionUploader;
exports.default = _default;