import React from "react";

import "./UL.scss";
const UL = ({ title, elementsList, id }) => {
  return (
    <div id={id} className="StylesGuideUl">
      <span>{title}</span>
      <ul>
        {elementsList?.map((element) => (
          <li key={element}>{element}</li>
        ))}
      </ul>
    </div>
  );
};

export default UL;
