import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import CommonHeader from "../../helpers/custom-react-components/common-header";
import ActivityLog from "./ActivityLog";
import html2pdf from "html2pdf.js";
import HeaderComponent from "./EntityViewerHeader";
import TabSegment from "./TabSegment";
class EntityViewer extends React.Component {
  constructor(props) {
    super(props);
    this.core = this.props.core;
    this.userprofile = this.props.core ? this.props.core.make("oxzion/profile").get().key : undefined;
    this.helper = this.core.make("oxzion/restClient");
    this.profileAdapter = this.core.make("oxzion/profile");
    this.messageBox = this.core.make("oxzion/messageDialog");
    this.profile = this.profileAdapter.get();
    this.appId = this.props.appId;
    this.fileId = this.props.fileId;
    this.proc = this.props.proc;
    this.extGUIComponents = this.props.extGUIComponents;
    this.unmounted = false;
    this.edit = this.props.edit;
    this.entityConfig = null;
    this.state = {
      content: this.props.content,
      fileData: this.props.fileData,
      entityId: null,
      showAuditLog: false,
      dataReady: false,
      editButton: null,
      filePanelUuid: this.uuidv4(),
      isTabSegment: false,
      allowHeader: false,
      marginTop: null,
      tabs: [],
      selectedTab: this.proc.args.selectedTab ?? "",
    };
  }

  stepDownPage() {
    let stepDownPage = new CustomEvent("stepDownPage", {
      detail: {},
      bubbles: true,
    });

    if (document.getElementById("navigation_" + this.props.appId)) {
      document.getElementById("navigation_" + this.props.appId).dispatchEvent(stepDownPage);
    }
  }

  handleEntityViewerRefresh = () => {
    this.setState({ dataReady: false }, () => {
      this.init();
    });
  };

  init() {
    const allowHeader = this.setAppUpgrade();
    this.getFileDetails(this.props.fileId).then((fileData) => {
      if (fileData.status == "success" && fileData.data && fileData.data.entity_id) {
        this.getEntityPage(fileData.data.entity_id).then((entityPage) => {
          var file = { ...fileData?.data?.data, ...fileData.data };
          this.generateEditButton(entityPage.data, file);
          this.entityConfig = entityPage.data;
          this.setState({
            entityId: file.entity_id,
            fileData: file,
            tabs: this.constructTabs(entityPage.data, file),
            dataReady: true,
          });
        });
      } else {
        this.stepDownPage();
        this.messageBox.show(fileData.message || "You do not have access to the File", "", "OK", false);
      }
    });
  }

  componentDidMount() {
    const customEvents = {
      handleEntityViewerRefresh: () => {
        this.handleEntityViewerRefresh();
      },
    };
    for (const key in customEvents) {
      document.getElementById("navigation_" + this.props.appId)?.addEventListener(key, customEvents[key], false);
    }
    this.init();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  async getFileDetails(fileId) {
    // .getMemoizedData method not returning new file Id's data so changed it to .request method
    return this.helper.request("v1", "/app/" + this.appId + "/file/" + fileId + "/data", {}, "get");
  }

  async getEntityPage(entityId) {
    let fileContent = await this.helper.request("v1", "/app/" + this.appId + "/entity/" + entityId + "/page", {}, "get");
    return fileContent;
  }

  updatePageContent = (config) => {
    let eventDiv = document.getElementById("navigation_" + this.appId);
    let ev2 = new CustomEvent("addPage", {
      detail: config,
      bubbles: true,
    });
    eventDiv.dispatchEvent(ev2);
  };

  printToPDF = async () => {
    this.state.tabs?.map((eachTab) => {
      if (eachTab.name === "Overview") {
        this.setState({
          selectedTab: eachTab.name
        })
      }
    });

    setTimeout(() => {
      let appName = this.props.proc?.metadata?.name;
      const element = document.querySelector(`[data-id="${appName}_Window"]`)?.querySelector(".active_tabstrip");
      if (element) {
        this.props.notif?.current?.notify("In Progress", "Please wait, PDF file will download automatically.", "success", "bottom-right", 10000);
        // Highlighting selected element
        element.classList.add("print-html-area-styles");
        let opt = {
          margin: [0.2, 0.5, 0.2, 0.5], //top,left,bottom,right
          filename: appName + Date.now() + ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        // Converting html element into pdf
        html2pdf()
          .set(opt)
          .from(element)
          .save()
          .then(() => element.classList.remove("print-html-area-styles"));
      } else {
        this.props.notif?.current?.notify("Failed", "PDF file download has been failed due to invalid element", "danger", "bottom-right", 10000);
      }
    }, 2000);
  };

  generateEditButton(entityConfig, fileData) {
    var fileId;
    let i = 0;
    const toolbarButtons = [];
    if (this.props.fileId) {
      fileId = this.props.fileId;
    } else {
      if (this.state.fileId) {
        fileId = this.state.fileId;
      }
    }
    if (entityConfig) {
      if (!entityConfig.has_workflow) {
        let formPage = {
          pageContent: [
            {
              type: "Form",
              form_id: entityConfig.form_uuid,
              name: entityConfig.form_name,
              fileId: fileId,
            },
          ],
          title: "Edit",
          icon: "far fa-pencil",
        };
        if (this.edit == 1) {
          this.updatePageContent(formPage);
          this.edit = 0;
        } else {
          var profile = this.userprofile;
          let showEdit = entityConfig?.edit_display_rule === "" ? true : eval(entityConfig?.edit_display_rule);
          showEdit &&
            toolbarButtons.push(
              <Button key={i++} title={"Edit"} className={"btn btn-primary"} primary={true} onClick={(e) => this.updatePageContent(formPage)}>
                <i className={"fa fa-pencil"} />
              </Button>,
            );
        }
      }
      if (entityConfig?.enable_print && !this?.state?.isTabSegment) {
        toolbarButtons.push(
          <Button key={i++} title={"Print"} className={"btn btn-primary"} primary={true} onClick={() => this.printToPDF()}>
            <i className={"fa fa-print"} />
          </Button>,
        );
      }
      if (entityConfig?.enableAuditLog && !this?.state?.isTabSegment) {
        toolbarButtons.push(
          <Button key={i++} title={"Audit Log"} className={"btn btn-primary"} primary={true} onClick={() => this.setState({ showAuditLog: true })}>
            <i className={"fa fa-history"} />
          </Button>,
        );
      }
      toolbarButtons.find((item) => item.title == "Generate Link")
        ? null
        : toolbarButtons.push(
            <Button
              title={"Share"}
              key={i++}
              className={"btn btn-primary"}
              primary={true}
              onClick={(e) => {
                this.props?.notif?.current?.notify("", "Link has been copied to clipboard", "success", undefined, 2000);
                this.core.make("oxzion/link").copyToClipboard(this.core.config("ui.url") + "?app=" + entityConfig.app_name + "&fileId=" + fileId);
              }}
            >
              <i className={"fa fa-share-alt"} />
            </Button>,
          );

      let ev = new CustomEvent("addcustomActions", {
        detail: { customActions: toolbarButtons, pageId: this.props.pageId },
        bubbles: true,
      });
      document.getElementById(this.appId + "_breadcrumbParent").dispatchEvent(ev);
    }
  }

  setAppUpgrade() {
    try {
      let { app_properties } = this.props.appData;
      const allowHeader = !!JSON.parse(app_properties).app_upgrade;
      this.setState({ allowHeader });
      return allowHeader;
    } catch (e) {
      return false;
    }
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  constructTabs(page, file) {
    var tabs = [];
    var that = this;
    var content = page?.content ? page.content : null;
    var finalContentArray = [];
    if (this.props?.item?.entityViewerForm || page?.view_as_form) {
      let formId = this.props.item.entityViewerForm ?? page.entity_viewer_form;
      const obj = {
        type: "Form",
        readOnly: true,
        form_id: formId,
        fileId: this.fileId,
      };
      finalContentArray.push(obj);
    } else {
      var content = page?.content ? page.content : null;
      if (content && content.length > 0) {
        content.map(function (key, index) {
          content[index]["fileId"] = that.fileId;
          finalContentArray.push(content[index]);
        });
      }
    }
    if (finalContentArray && page?.enable_view) {
      tabs.push({
        name: "Overview",
        uuid: that.state.filePanelUuid,
        content: finalContentArray,
        className: "overview-tab-container",
      });
    }
    if (page?.enable_documents != "0") {
      tabs.push({
        name: "Attachments",
        uuid: generateUUID(),
        content: [{ type: "DocumentViewer", url: "file/" + this.fileId + "/document" }],
      });
    }
    if (page?.enable_comments != "0") {
      tabs.push({
        name: "Activity",
        uuid: this.uuidv4(),
        content: [
          {
            ...this.props,
            type: "Comment",
            appId: this.appId,
            fileId: this.fileId,
            core: this.core,
            disableControls: true,
          },
        ],
      });
    }
    if (page?.enable_auditlog != "0") {
      tabs.push({
        name: "Audit Logs",
        uuid: this.uuidv4(),
        content: [
          {
            ...this.props,
            type: "History",
            appId: this.appId,
            fileId: this.fileId,
            core: this.core,
            disableControls: true,
          },
        ],
      });
    }
    if (page?.enable_relation != "0") {
      let that = this;
      page?.relations_config?.map(function (key, index) {
        let columnConfiguration = [];
        page.relations_config[index].selectColumns.map(function (columns, colIndex) {
          columnConfiguration.push({
            title: columns.text,
            field: columns.name,
            filter: columns.data_type,
          });
        });
        tabs.push({
          name: page.relations_config[index].relationTabName,
          content: [
            {
              gridContent: {
                relation_name: page.relations_config[index].relationTabName,
                route: `file/${that.fileId}/filerelation/${page.relations_config[index].relationsRoute}`,
                relationsParentRoute: `${page.relations_config[index].addRelationRoute}`,
                parent_fileId: `${that.fileId}`,
                disableAppId: false,
                columnConfig: columnConfiguration,
                actions: [
                  {
                    name: "View",
                    icon: "fa fa-eye",
                    rule: "true",
                    defaultAction: false,
                    details: [
                      {
                        type: "EntityViewer",
                      },
                    ],
                    Name: "View",
                  },
                ],
                //   "operations": {
                //     "actions": [
                //         {
                //             "details": [
                //                 {
                //                     "type": "Form",
                //                     "formSource": "existingForm",
                //                     "form_id": "66a85750-ac48-48b0-88af-11ed8eeb36b3"
                //                 }
                //             ],
                //             "name": "Create",
                //             "icon": "fas fa-plus",
                //             "rule": "true"
                //         }
                //     ],
                //     "title": "Operation"
                // }
              },
              type: "List",
              appId: that.appId,
              fileId: that.fileId,
              core: that.core,
            },
          ],
          uuid: that.uuidv4(),
          isRelationTab: true,
        });
      });
    }

    //!juggad fix :( , need to bring contentActionButtons into header component to actually fix it
    setTimeout(() => {
      let element = document.getElementById("customActions");
      this.setState({
        marginTop: element ? "40px" : "0px",
      });
    }, 500);

    return tabs;
  }

  render() {
    if (this.state.dataReady) {
      return (
        <div className="contentDiv">
          {this.state.allowHeader && <CommonHeader appId={this.appId} core={this.core} fileData={this.state.fileData} />}
          <div>
            <div className="entityViewerHeader" style={{ marginTop: this.state.marginTop, position: "relative", zIndex: "2" }}>
              <HeaderComponent
                entityDetails={this.entityConfig}
                appId={this.appId}
                fileId={this.props.fileId}
                core={this.core}
                fileData={this.state.fileData}
                pageId={this.uuidv4()}
                proc={this.proc}
              />
            </div>
            <TabSegment
              appId={this.appId}
              notif={this.props.notif}
              core={this.core}
              proc={this.proc}
              fileId={this.state.fileId}
              tabs={this.state.tabs}
              pageId={this.uuidv4()}
              currentRow={this.state.fileData}
              selectedTab={this.state.selectedTab}
            />
          </div>
          {this.state.showAuditLog && (
            <ActivityLog cancel={() => this.setState({ showAuditLog: false })} appId={this.appId} fileId={this.fileId} core={this.core} />
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default EntityViewer;

var generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};