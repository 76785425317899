import {
  PrimaryButton,
  SecondaryButton,
  ToggleButton,
  RadioButton,
  CheckBox,
} from "./Buttons";
import { H1, H2, H3, P } from "./Titles";
import { OL, UL } from "./Lists";

const Components = {
  PrimaryButton,
  SecondaryButton,
  ToggleButton,
  RadioButton,
  CheckBox,
  H1,
  H2,
  H3,
  P,
  OL,
  UL,
};

export default Components;
