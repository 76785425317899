const components = [
    {
        "label": "Groups",
        "widget": "choicesjs",
        "placeholder": "Select Groups",
        "tooltip": "Select a Group for this task.",
        "tabindex": "3",
        "tableView": false,
        "multiple": true,
        "dataSrc": "url",
        "data": {
            "url": "/teams/list",
            "headers": [{
                "key": "",
                "value": ""
            }],
            "values": [{
                "label": "",
                "value": ""
            }],
            "json": "",
            "resource": "",
            "custom": ""
        },
        "valueProperty": "uuid",
        "template": "<span>{{ item.name }}</span>",
        "validate": {
            "multiple": true,
            "required": false,
            "custom": "",
            "customPrivate": false,
            "strictDateValidation": false,
            "unique": false,
            "onlyAvailableItems": false
        },
        "key": "assigned_team",
        "properties": {
            "absoluteUrl": "true",
            "data_type": "text"
        },
        "type": "select",
        "selectValues": "data",
        "filter": "filter=[{\"skip\":0,\"take\":10000}]",
        "input": true,
        "hideOnChildrenHidden": false,
        "disableLimit": false,
        "lazyLoad": false,
        "prefix": "",
        "customClass": "",
        "suffix": "",
        "defaultValue": null,
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "refreshOn": "",
        "redrawOn": "",
        "modalEdit": false,
        "dataGridLabel": false,
        "labelPosition": "top",
        "description": "",
        "errorLabel": "",
        "hideLabel": false,
        "disabled": false,
        "autofocus": false,
        "dbIndex": false,
        "customDefaultValue": "",
        "calculateValue": "",
        "calculateServer": false,
        "attributes": {},
        "validateOn": "change",
        "conditional": {
            "show": null,
            "when": null,
            "eq": ""
        },
        "overlay": {
            "style": "",
            "left": "",
            "top": "",
            "width": "",
            "height": ""
        },
        "allowCalculateOverride": false,
        "encrypted": false,
        "showCharCount": false,
        "showWordCount": false,
        "allowMultipleMasks": false,
        "idPath": "id",
        "clearOnRefresh": false,
        "limit": 100,
        "searchEnabled": true,
        "searchField": "",
        "minSearch": 0,
        "readOnlyValue": false,
        "authenticate": false,
        "ignoreCache": false,
        "selectFields": "",
        "selectThreshold": 0.3,
        "uniqueOptions": false,
        "fuseOptions": {
            "include": "score",
            "threshold": 0.3
        },
        "indexeddb": {
            "filter": {}
        },
        "customOptions": {},
        "useExactSearch": false,
        "id": "e4z0217"
    }
]
export default components;