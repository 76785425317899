import React from "react";
import Page from "./components/App/Page";
import FormRender from "./components/App/FormRender";
import { createBrowserHistory } from "history";
import { Chip } from "@progress/kendo-react-buttons";
import Requests from "./Requests";
import Notification from "./Notification";
import ModalPopup from "./components/modal-popups";
import { Utils } from "./helpers";

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.history = createBrowserHistory();
    this.core = this.props.core;
    this.appId = this.props.appId;
    this.proc = this.props.proc;
    this.params = this.props.params;
    this.notif = React.createRef();
    this.pageClass = this.appId + "_page";
    this.pageDiv = this.appId + "_pages";
    this.appNavigationDiv = "navigation_" + this.appId;
    this.extGUIComponents = null;
    this.is_base_app = this.proc?.metadata?.is_base_app;
    this.state = {
      selected: this.props.selected,
      customActions: [],
      pages: [],
      mappedActions: {},
    };
    this.homepage = null;
    this.breadcrumbDiv = this.appId + "_breadcrumbParent";
    this.contentDivID = this.appId + "_Content";
  }

  pageActive(pageId) {
    if (document.getElementById(pageId + "_page")) {
      document.getElementById(pageId + "_page").classList.remove("page-inactive");
      document.getElementById(pageId + "_page").classList.add("page-active");
    }
  }
  pageInActive(pageId, dimPage) {
    if (document.getElementById(pageId + "_page")) {
      document.getElementById(pageId + "_page").classList.add(dimPage ? "page-dimmed" : "page-inactive");
      document.getElementById(pageId + "_page").classList.remove("page-active");
    }
  }

  async fetchExternalComponents() {
    const components = await import(`./externals/${this.appId}/index.js`);
    return components;
  }

  componentDidMount() {
    // call the function only if it not a baseApp;
    if (!this.is_base_app) {
      this.fetchExternalComponents().then((result) => {
        this.extGUIComponents = result;
      }).catch((error) => {
        console.error('Failed to load external components:', error);
      });
    }
    let customEventHandlers = {
      addPage: (e) => {
        var that = this;
        that.addPage(e);
      },
      stepDownPage: (e) => {
        var that = this;
        that.stepDownPage(e);
      },
      selectPage: this.selectPage,
      addcustomActions: this.addcustomActions,
    };
    for (const key in customEventHandlers) {
      document.getElementById(this.appNavigationDiv).addEventListener(key, customEventHandlers[key], false);
    }
    document.getElementById(this.appNavigationDiv).dispatchEvent(
      new CustomEvent("generateBreadCrumbs", {
        details: {
          data: [...this.state.pages.map((page) => ({ ...page, type: "page" }))],
        },
      }),
    );

    //moved from constructor
    if (this.props.menus && this.props.menus.length > 0) {
      this.props.menuLoad(this.props.menus);
      if (this.props.menus[0]) {
        this.homepage = this.props.menus[0];
      }
    } else {
      Requests.getMenulist(this.core, this.appId).then((response) => {
        this.props.menuLoad(response["data"]);
        if (response["data"] && response["data"][0]) {
          if (response["data"][0].submenu) {
            this.homepage = response["data"][0].submenu[0];
          } else {
            this.homepage = response["data"][0];
          }
        }
        if (this.params && this.params.page) {
          this.setState({
            pages: [
              {
                pageId: this.params.page,
                title: this.params.pageTitle,
                icon: this.params.pageIcon,
              },
            ],
          });
          this.pageActive(this.params.page);
          this.history.push("/");
        } else if (this.params && this.params.formId) {
          this.props.selectLoad(this.homepage);
          this.addPage({
            detail: {
              pageContent: [
                {
                  type: "Form",
                  fileId: this.params.fileId,
                  form_id: this.params.formId,
                },
              ],
              title: "Edit",
              icon: "fa fa-pencil",
              fileId: this.params.fileId,
              form_id: this.params.formId,
            },
          });
          this.history.push("/");
        } else if (this.params && this.params.fileId) {
          this.props.selectLoad(this.homepage);
          this.addPage({
            detail: {
              pageContent: [
                {
                  type: "EntityViewer",
                  fileId: this.params.fileId,
                  edit: this.params.edit,
                },
              ],
              title: "View",
              icon: "fa fa-eye",
              fileId: this.params.fileId,
            },
          });
          // this.pageActive(this.params.page);
          this.history.push("/");
        } else if (this.params && this.params.activityId) {
          this.setState({ selected: { activity_id: this.params.activityId } });
        } else if (this.proc && this.proc.args) {
          if (typeof this.proc.args === "string") {
            try {
              var appParams = JSON.parse(this.proc.args);
              if (appParams.type) {
                this.setState({
                  selected: {
                    type: appParams.type,
                    page_id: appParams.pageId,
                    pipeline: appParams.pipeline,
                    workflow_id: appParams.workflowId,
                    parentWorkflowInstanceId: appParams.workflowInstanceId,
                    workflowInstanceId: appParams.workflowInstanceId,
                    url: appParams.url,
                    activityInstanceId: appParams.activityInstanceId,
                  },
                });
                this.pageActive(appParams.pageId);
                this.history.push("/");
              } else {
                this.history.push("/");
                let ev = new CustomEvent("addPage", {
                  detail: { pageContent: appParams.detail },
                  bubbles: true,
                });
                document.getElementsByClassName(this.breadcrumbDiv)[0].dispatchEvent(ev);
              }
            } catch (e) {
              console.error(e);
              this.props.selectLoad(this.homepage);
            }
          } else {
            this.props.selectLoad(this.homepage);
          }
        } else {
          this.props.selectLoad(this.homepage);
        }
      });
    }
  }

  addPage = (e) => {
    if (!e.detail.pageId) {
      e.detail.pageId = Utils.generateUUID() + "_temppage";
    }
    if (e.detail.getPageCallback) {
      const item = e.detail;
      var pageId = item.pageId + "_page";
      var pageClasses = this.pageClass + " page-active";
      return e.detail.getPageCallback(
        <div className={pageClasses} id={pageId} key={pageId}>
          <Page
            key={item.pageId}
            config={this.props.config}
            proc={this.props.proc}
            app={this.props.appId}
            core={this.core}
            fileId={item.fileId}
            pageId={item.pageId}
            notif={this.notif}
            params={item.params}
            pageContent={item.pageContent}
            currentRow={item.currentRow}
            popupConfig={item.popupConfig}
            {...item}
            extGUIComponents={this.extGUIComponents}
          />
        </div>,
      );
    }
    var that = this;
    var pages = this.state.pages;
    if (e.detail.fileId) {
      var filePage = [{ type: "EntityViewer", fileId: e.detail.fileId }];
      var pageContent = {
        pageContent: filePage,
        title: "View",
        icon: "fa fa-eye",
        fileId: e.detail.fileId,
        ...e.detail,
      };
      if (!this.checkIfEntityViewerPageExists(pageContent)) {
        pages.push(pageContent);
      } else {
        pages.splice(pages.length - 1, 1);
        this.setState({
          pages: pages,
        });
        setTimeout(function () {
          that.addPage({ detail: pageContent });
        }, 1000);
      }
    } else {
      if (!this.checkIfEntityViewerPageExists(e.detail)) {
        if (e.detail.refreshPages) {
          document.getElementById(this.appNavigationDiv).dispatchEvent(new CustomEvent("clearBreadCrumbs"));
          pages = [];
          pages.push(e.detail);
        } else pages.push(e.detail);
      } else {
        pages.push(e.detail);
        this.setState({
          pages: pages,
        });
        document.getElementById(this.appNavigationDiv).dispatchEvent(new CustomEvent("handleOIRefresh", { detail: { hideLoader: true }, bubbles: true }));
      }
    }
    if (e.detail.parentPage && document.getElementById(e.detail.parentPage + "_page")) {
      this.pageInActive(e.detail.parentPage, e.detail.popupConfig ? true : false);
    } else {
      if (pages.length === 1) {
        this.pageActive(pages[pages.length - 1].pageId);
      } else if (pages.length > 1) {
        this.pageInActive(pages[pages.length - 2].pageId);
      } else {
        null;
      }
    }
    this.setState({ pages }, () => {
      document.getElementById(this.appNavigationDiv).dispatchEvent(
        new CustomEvent("generateBreadCrumbs", {
          detail: {
            data: [...pages.map((page) => ({ ...page, type: "page" }))],
          },
        }),
      );
      this.resetCustomActions();
    });
    this.props.menus?.length > 1 ? this.props.selectLoad(this.state.selected) : this.props.selectLoad({});
  };

  selectPage = (e) => {
    this.resetCustomActions();
    this.pageActive(e.detail.parentPage);
  };

  addcustomActions = (e) => {
    let mappedActions = {};
    if (e.detail?.pageId) {
      const clone = { ...this.state.mappedActions };
      clone[e.detail.pageId] = e.detail.customActions;
      mappedActions = { mappedActions: clone };
    }
    this.setState({ customActions: e.detail.customActions, ...mappedActions });
  };

  checkIfEntityViewerPageExists(page) {
    //!refactered version need to test
    // const lastPage = this.state.pages[this.state.pages.length - 1]?.pageContent?.[0];
    // const firstPage = page.pageContent?.[0]?.type;

    // if (lastPage && firstPage) {
    //   switch (lastPage.type) {
    //     case "EntityViewer":
    //       return firstPage === "Form" || firstPage === "Comment";
    //     case "Form":
    //       return firstPage === "EntityViewer" || firstPage === "Comment";
    //     case "Comment":
    //       return firstPage === "EntityViewer" || firstPage === "Form";
    //   }
    // }
    // return false
    //!refactered version need to test ^

    var last_page_key = this.state.pages.length - 1;
    var pages = this.state.pages;
    if (
      this.state.pages[last_page_key] &&
      this.state.pages[last_page_key].pageContent &&
      this.state.pages[last_page_key].pageContent[0] &&
      this.state.pages[last_page_key].pageContent[0].type == "EntityViewer" &&
      page.pageContent &&
      (page.pageContent[0].type == "Form" || page.pageContent[0].type == "Comment")
    ) {
      return true;
    }
    if (
      this.state.pages[last_page_key] &&
      this.state.pages[last_page_key].pageContent &&
      this.state.pages[last_page_key].pageContent[0] &&
      this.state.pages[last_page_key].pageContent[0].type == "Form" &&
      page.pageContent &&
      (page.pageContent[0].type == "EntityViewer" || page.pageContent[0].type == "Comment")
    ) {
      return true;
    }
    if (
      this.state.pages[last_page_key] &&
      this.state.pages[last_page_key].pageContent &&
      this.state.pages[last_page_key].pageContent[0] &&
      this.state.pages[last_page_key].pageContent[0].type == "Comment" &&
      page.pageContent &&
      (page.pageContent[0].type == "EntityViewer" || page.pageContent[0].type == "Form")
    ) {
      return true;
    }
    if (
      this.state.pages[last_page_key] &&
      this.state.pages[last_page_key].pageContent &&
      this.state.pages[last_page_key].pageContent[0] &&
      this.state.pages[last_page_key].pageContent[0].type == "EntityViewer" &&
      page.pageContent &&
      page.pageContent[0].type == "EntityViewer"
    ) {
      return true;
    }
    return false;
  }

componentDidUpdate(prevProps, prevState) {
    if (prevProps.selected != this.props.selected) {
      var item = this.props.selected;
      if (item && item.page_id) {
        this.setState({ pages: [], selected: this.props.selected });
        var page = [{ pageId: item.page_id, title: item.name }];
        this.setState(
          {
            pages: page,
            customActions: this.state.mappedActions?.[item.page_id] || [],
          },
          () => {
            this.pageActive(item.page_id);
            const ev = new CustomEvent("generateBreadCrumbs", {
              detail: {
                data: [
                  ...this.state.pages.map((page) => ({
                    ...page,
                    type: "page",
                  })),
                ],
              },
            });
            document.getElementById(this.appNavigationDiv).dispatchEvent(ev);
          },
        );
      }
    }
    if (prevState.pages.length < this.state.pages.length) {
      let ev = new CustomEvent("generateBreadCrumbs", {
        detail: {
          data: [...this.state.pages.map((page) => ({ ...page, type: "page" }))],
        },
      });
      if (document.getElementById(this.appNavigationDiv)) {
        document.getElementById(this.appNavigationDiv).dispatchEvent(ev);
      }
    }

    this.state.pages.length > 0 && this.renderBreadcrumbs();
  }

  stepDownPage = (e) => {
    // if (this.state.pages.length == 1) {
    //   this.props.selectLoad(this.homepage);
    // }
    if (this.state.pages.length > 1) {
      this.setState(
        (prevState) => {
          const data = prevState.pages.slice();
          if (data.length > 1) {
            data.splice(data.length - 1, data.length);
          }
          return {
            pages: data,
            customActions: this.state.mappedActions?.[data[data.length - 1]["pageId"]] || [],
          };
        },
        () => {
          if (e?.detail?.activatePage) this.pageActive(e.detail.activatePage);
          else this.pageActive(this.state.pages[this.state.pages.length - 1]["pageId"]);
          document.getElementById(this.appNavigationDiv)?.dispatchEvent(new CustomEvent("stepDownOneBreadCrumb"));
          document.getElementById(this.appNavigationDiv)?.dispatchEvent(new CustomEvent("handleOIRefresh", { detail: { hideLoader: true, filters : e?.detail?.dashboardFilter }, bubbles: true }));
          //checking if current page loaded contains entity viewer before raising the event as entity viewer is getting rendered on everystepdown
          if (this.state.pages[this.state.pages.length - 1]?.pageContent?.some((pageContentData) => pageContentData.type.toLowerCase() === "entityviewer"))
            document.getElementById(this.appNavigationDiv)?.dispatchEvent(new CustomEvent("handleEntityViewerRefresh"));
        },
      );
    }
    else {
      try {
        document.getElementById(this.appNavigationDiv)?.dispatchEvent(new CustomEvent("clearBreadCrumbs"));
        this.props.selectLoad(this.homepage);
      } catch (error) {
        console.warn("Error in stepDownPage", error);
      }
    }
  };

  resetCustomActions() {
    this.setState((prevState) => {
      let pagesCopy = [...prevState.pages];
      if (pagesCopy[pagesCopy.length - 2]) pagesCopy[pagesCopy.length - 2].customActions = prevState.customActions;
      return { customActions: null, pages: pagesCopy };
    });
    let ev = new CustomEvent("getCustomActions", {
      detail: {},
      bubbles: true,
    });
    document.getElementById(this.appNavigationDiv).dispatchEvent(ev);
    // var navigationElement = document.getElementById("navigation_" + this.appId);
    // if (navigationElement && navigationElement.getElementsByClassName("page-active") && navigationElement.getElementsByClassName("page-active")[0]) {
    //   var foundElement = this.getElementInsideElement(navigationElement.getElementsByClassName("page-active")[0], "customActionsToolbar");
    //   if (foundElement) {
    //     foundElement.dispatchEvent(ev);
    //   }
    // }
  }
  resetPageCustomActions() {
    this.setState({ customActions: [] });
  }
  getElementInsideElement(baseElement, wantedElementID) {
    var elementToReturn;
    for (var i = 0; i < baseElement.childNodes.length; i++) {
      elementToReturn = baseElement.childNodes[i];
      if (elementToReturn.id == wantedElementID) {
        return elementToReturn;
      } else {
        elementToReturn = this.getElementInsideElement(elementToReturn, wantedElementID);
        if (elementToReturn) {
          return elementToReturn;
        }
      }
    }
  }
  breadcrumbClick = (currentValue, index) => {
    let data = this.state.pages.slice();
    data.splice(index + 1, data.length);
    this.setState({
      pages: data,
    });
    this.pageActive(currentValue.pageId);
    this.resetCustomActions();
  };

  renderBreadcrumbs = () => {
    var breadcrumbsList = [];
    this.state.pages.map((currentValue, index) => {
      var clickable = false;
      if (this.state.pages.length > 1 && index + 1 != this.state.pages.length) {
        clickable = true;
      } else {
        return null;
      }
      currentValue.title
        ? breadcrumbsList.push(
          <>
            {index == "0" ? null : (
              <div
                style={{
                  marginRight: "5px",
                }}
              >
                <i className="fas fa-angle-right" style={{ marginRight: "-5px" }}></i>
              </div>
            )}
            <Chip
              text={currentValue.title}
              value={""}
              disabled={!clickable}
              className={clickable ? "activeBreadcrumb" : "disabledBreadcrumb"}
              icon={currentValue.icon}
              type={clickable || index == 0 ? "none" : "info"}
              selected={false}
              onClick={() => {
                clickable ? this.breadcrumbClick(currentValue, index) : null;
              }}
              removable={false}
            />
          </>,
        )
        : null;
    });
    return breadcrumbsList;
  };

  renderPages() {
    var pageList = [];
    var that = this;
    if (this.state.pages.length > 0) {
      this.state.pages.map((item, i) => {
        var pageId = item.pageId + "_page";
        var pageClasses = this.pageClass + " page-active";
        // if (i == this.state.pages.length - 1 || true) {
        pageList.push(
          <div className={pageClasses} id={pageId} key={pageId + i}>
            <Page
              key={item.pageId}
              config={this.props.config}
              proc={this.props.proc}
              app={this.props.appId}
              core={this.core}
              fileId={item.fileId}
              pageId={item.pageId}
              notif={this.notif}
              params={item.params}
              pageContent={item.pageContent}
              currentRow={item.currentRow}
              popupConfig={item.popupConfig}
              {...item}
              extGUIComponents={this.extGUIComponents}
            />
          </div>,
        );
        // } else {
        //   pageList.push(<div></div>);
        // }
      });
    }
    return pageList;
  }

  render() {
    return (
      <div id={this.appNavigationDiv} className="Navigation">
        <ModalPopup id={this.appNavigationDiv} core={this.core} />
        <Notification ref={this.notif} />
        <div className={this.breadcrumbDiv + " breadcrumbHeader"} id={this.breadcrumbDiv}>
          {/* setting customActions */}
          {this.state.pages.length > 0 && this.props.setCustomActions(this.state.customActions?.length > 0 ? this.state.customActions : this.state.pages[this.state.pages.length-1]?.customActions)}
          <div className={this.pageDiv} style={{ height: "calc(100% - 55px)" }}>
            {this.state.pages.length > 0 && !this.state.reloadInProgress ? this.renderPages() : null}
            {(this.state.selected.activityInstanceId && this.state.selected.activityInstanceId) || this.state.selected.pipeline ? (
              <div id={this.contentDivID} className="AppBuilderPage">
                <FormRender
                  core={this.core}
                  appId={this.props.appId}
                  notif={this.notif}
                  activityInstanceId={this.state.selected.activityInstanceId}
                  workflowInstanceId={this.state.selected.workflowInstanceId}
                  pipeline={this.state.selected.pipeline}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
export default Navigation;

// The params to open a specific page must be sent in the following format:
// http://localhost:8081/?app=DiveInsurance
//  &params= {
//   "name": "Quote Approval",
//   "detail": [{
//     "type": "Form",
//     "pipeline": {
//       "activityInstanceId": "629256b1-82f4-11ea-ba01-bacc68b07eda",
//       "workflowInstanceId": "5e8ea8c0-82f4-11ea-ba01-bacc68b07eda",
//       "commands": [{
//         "command": "claimForm"
//       }, {
//         "command": "instanceForm"
//       }]
//     }
//   }]
// }
