import React from "react";

import "./H3.scss";

const H3 = ({ text, onClicked, id }) => {
  return (
    <h3 className="StylesGuideH3" id={id} onClicked={onClicked}>
      {text}
    </h3>
  );
};

export default H3;
