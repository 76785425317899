const components = [
    {
        "label": "Observers",
        "widget": "choicesjs",
        "placeholder": "Select observers",
        "tooltip": "Select one or more observers for this task.",
        "tabindex": "3",
        "tableView": false,
        "multiple": true,
        "dataSrc": "url",
        "data": {
            "url": "/user",
            "headers": [{
                "key": "",
                "value": ""
            }],
            "values": [{
                "label": "",
                "value": ""
            }],
            "json": "",
            "resource": "",
            "custom": ""
        },
        "valueProperty": "uuid",
        "template": "<span>{{ item.name }}</span>",
        "validate": {
            "multiple": true,
            "required": false,
            "custom": "",
            "customPrivate": false,
            "strictDateValidation": false,
            "unique": false,
            "onlyAvailableItems": false
        },
        "key": "observers",
        "properties": {
            "absoluteUrl": "true",
            "data_type": "text"
        },
        "type": "select",
        "selectValues": "data",
        "filter": "filter=[{\"skip\":0,\"take\":10000}]",
        "input": true,
        "hideOnChildrenHidden": false,
        "disableLimit": false,
        "lazyLoad": false,
        "prefix": "",
        "customClass": "",
        "suffix": "",
        "defaultValue": null,
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "refreshOn": "",
        "redrawOn": "",
        "modalEdit": false,
        "dataGridLabel": false,
        "labelPosition": "top",
        "description": "",
        "errorLabel": "",
        "hideLabel": false,
        "disabled": false,
        "autofocus": false,
        "dbIndex": false,
        "customDefaultValue": "",
        "calculateValue": "",
        "calculateServer": false,
        "attributes": {},
        "validateOn": "change",
        "conditional": {
            "show": null,
            "when": null,
            "eq": ""
        },
        "overlay": {
            "style": "",
            "left": "",
            "top": "",
            "width": "",
            "height": ""
        },
        "allowCalculateOverride": false,
        "encrypted": false,
        "showCharCount": false,
        "showWordCount": false,
        "allowMultipleMasks": false,
        "idPath": "id",
        "clearOnRefresh": false,
        "limit": 100,
        "searchEnabled": true,
        "searchField": "",
        "minSearch": 0,
        "readOnlyValue": false,
        "authenticate": false,
        "ignoreCache": false,
        "selectFields": "",
        "selectThreshold": 0.3,
        "uniqueOptions": false,
        "fuseOptions": {
            "include": "score",
            "threshold": 0.3
        },
        "indexeddb": {
            "filter": {}
        },
        "customOptions": {},
        "useExactSearch": false,
        "id": "e4z0217"
    },{
        "label": "observersname",
        "redrawOn": "observers",
        "calculateValue": "for(let i=0; i< observers.length; i++)\n{\n  if(data.observers[i].name == undefined && data.userprofile[i]){\n  value = data.userprofile[i].name;\n  } \n  else {\n  value = data.observers[i].name;\n  }\n}\n",
        "key": "observersname",
        "type": "hidden",
        "input": true,
        "tableView": false,
        "hideOnChildrenHidden": false,
        "dataGridLabel": false,
        "placeholder": "",
        "prefix": "",
        "customClass": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": null,
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "refreshOn": "",
        "modalEdit": false,
        "labelPosition": "top",
        "description": "",
        "errorLabel": "",
        "tooltip": "",
        "hideLabel": false,
        "tabindex": "",
        "disabled": false,
        "autofocus": false,
        "dbIndex": false,
        "customDefaultValue": "",
        "calculateServer": false,
        "widget": {
          "type": "input"
        },
        "attributes": {},
        "validateOn": "change",
        "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false
        },
        "conditional": {
          "show": null,
          "when": null,
          "eq": ""
        },
        "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
        },
        "allowCalculateOverride": false,
        "encrypted": false,
        "showCharCount": false,
        "showWordCount": false,
        "properties": {},
        "allowMultipleMasks": false,
        "inputType": "hidden",
        "id": "e3jhluy"
      }
];
export default components;
