import React from "react";
import "./SecondaryButton.scss";

const SecondaryButton = ({ onClicked, id, text, disabled }) => {
  return (
    <button
      onClick={disabled ? () => console.log("diasbled") : onClicked}
      className={disabled ? "disabled" : "secondaryButton"}
      id={id}
    >
      {text}
    </button>
  );
};

export default SecondaryButton;
