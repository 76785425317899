const components = [
	{
		"label": "userlist",
		"key": "userlist",
		"properties": {
			"custom_list": "user_list"
		},
		"type": "hidden",
		"input": true,
		"tableView": false,
		"validate": {
			"unique": false,
			"multiple": false
		},
		"hideOnChildrenHidden": false
	},{
		"label": "userlist",
		"customDefaultValue": "value = data.userlist;",
		"key": "userlist",
		"type": "hidden",
		"input": true,
		"tableView": false,
		"placeholder": "",
		"prefix": "",
		"customClass": "",
		"suffix": "",
		"multiple": false,
		"defaultValue": null,
		"protected": false,
		"unique": false,
		"persistent": true,
		"hidden": false,
		"clearOnHide": true,
		"refreshOn": "",
		"redrawOn": "",
		"modalEdit": false,
		"dataGridLabel": false,
		"labelPosition": "top",
		"description": "",
		"errorLabel": "",
		"tooltip": "",
		"hideLabel": false,
		"tabindex": "",
		"disabled": false,
		"autofocus": false,
		"dbIndex": false,
		"calculateValue": "",
		"calculateServer": false,
		"widget": {
			"type": "input"
		},
		"attributes": {},
		"validateOn": "change",
		"validate": {
			"required": false,
			"custom": "",
			"customPrivate": false,
			"strictDateValidation": false,
			"multiple": false,
			"unique": false
		},
		"conditional": {
			"show": null,
			"when": null,
			"eq": ""
		},
		"overlay": {
			"style": "",
			"left": "",
			"top": "",
			"width": "",
			"height": ""
		},
		"allowCalculateOverride": false,
		"encrypted": false,
		"showCharCount": false,
		"showWordCount": false,
		"properties": {},
		"allowMultipleMasks": false,
		"addons": [],
		"inputType": "hidden",
		"id": "eh77p6s"
	}
];
export default components;
