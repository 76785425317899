const components = [
  {
    label: "End Date",
    format: "yyyy-MM-dd",
    placeholder: "End Date",
    tooltip: "Please select the end date for the task.",
    tabindex: "6",
    tableView: false,
    enableMinDateInput: true,
    datePicker: {
      minDate: "moment(data['next_action_date_protected'])",
      maxDate: "moment().add(data.end_date_protected).calendar();",
      disableWeekends: false,
      disableWeekdays: false,
      datepickerMode: "day",
      showWeeks: true,
      startingDay: 0,
      initDate: "",
      minMode: "day",
      maxMode: "year",
      yearRows: 4,
      yearColumns: 5,
    },
    enableMaxDateInput: true,
    enableTime: false,
    defaultDate: "moment().add(7, 'days');",
    persistent: false,
    protected: true,
    customDefaultValue: "value = data.end_date;",
    validate: {
      required: true,
      custom: "valid = !((moment(data.end_date_protected).diff(moment(data['next_action_date_protected'])) >=0)) || !((moment(data.end_date_protected).diff(moment(data['start_date_protected'])) >=0)) ? ' ' : true;",
      customPrivate: false,
      strictDateValidation: false,
      multiple: false,
      unique: false,
    },
    key: "end_date_protected",
    type: "datetime",
    input: true,
    lockKey: true,
    hideOnChildrenHidden: false,
    widget: {
      type: "calendar",
      displayInTimezone: "viewer",
      locale: "en",
      useLocaleSettings: false,
      allowInput: true,
      mode: "single",
      enableTime: false,
      noCalendar: false,
      format: "yyyy-MM-dd",
      hourIncrement: 1,
      minuteIncrement: 1,
      time_24hr: false,
      minDate: "moment(data['next_action_date_protected'])",
      disableWeekends: false,
      disableWeekdays: false,
      maxDate: "moment().add(data.end_date_protected).calendar();",
    },
    prefix: "",
    customClass: "",
    suffix: "",
    multiple: false,
    defaultValue: "",
    unique: false,
    hidden: false,
    clearOnHide: true,
    refreshOn: "",
    redrawOn: "",
    modalEdit: false,
    dataGridLabel: false,
    labelPosition: "top",
    description: "",
    errorLabel: "",
    hideLabel: false,
    disabled: false,
    autofocus: false,
    dbIndex: false,
    calculateValue: "",
    calculateServer: false,
    attributes: {},
    validateOn: "change",
    conditional: {
      show: null,
      when: null,
      eq: "",
    },
    overlay: {
      style: "",
      left: "",
      top: "",
      width: "",
      height: "",
    },
    allowCalculateOverride: false,
    encrypted: false,
    showCharCount: false,
    showWordCount: false,
    properties: {},
    allowMultipleMasks: false,
    useLocaleSettings: false,
    allowInput: true,
    enableDate: true,
    displayInTimezone: "viewer",
    timezone: "",
    datepickerMode: "day",
    timePicker: {
      hourStep: 1,
      minuteStep: 1,
      showMeridian: true,
      readonlyInput: false,
      mousewheel: true,
      arrowkeys: true,
    },
    customOptions: {},
    id: "els1apo",
  },
  {
    label: "End Date",
    redrawOn: "close_date",
    customDefaultValue: "let now = moment(data.close_date).toISOString();\nvalue = now.split('T')[0];\nconsole.log(value);",
    key: "end_date",
    properties: {
      data_type: "Date",
    },
    type: "hidden",
    tableView: false,
    input: true,
    dataGridLabel: false,
    hideOnChildrenHidden: false,
    placeholder: "",
    prefix: "",
    customClass: "",
    suffix: "",
    multiple: false,
    defaultValue: null,
    protected: false,
    unique: false,
    persistent: true,
    hidden: false,
    clearOnHide: true,
    refreshOn: "",
    modalEdit: false,
    labelPosition: "top",
    description: "",
    errorLabel: "",
    tooltip: "",
    hideLabel: false,
    tabindex: "",
    disabled: false,
    autofocus: false,
    dbIndex: false,
    calculateValue: "",
    calculateServer: false,
    widget: {
      type: "input",
    },
    attributes: {},
    validateOn: "change",
    validate: {
      required: false,
      custom: "",
      customPrivate: false,
      strictDateValidation: false,
      multiple: false,
      unique: false,
    },
    conditional: {
      show: null,
      when: null,
      eq: "",
    },
    overlay: {
      style: "",
      left: "",
      top: "",
      width: "",
      height: "",
    },
    allowCalculateOverride: false,
    encrypted: false,
    showCharCount: false,
    showWordCount: false,
    allowMultipleMasks: false,
    inputType: "hidden",
    id: "eu94c4",
  },
//   {
//     label: "HTML",
//     attrs: [
//       {
//         attr: "",
//         value: "",
//       },
//     ],
//     content: "End date cannot be less than Next action date or Start date",
//     refreshOnChange: false,
//     hidden: true,
//     key: "edvalidation",
//     customConditional: "show = !((moment(data.end_date_protected).diff(moment(data['next_action_date_protected'])) >=0)) || !((moment(data.end_date_protected).diff(moment(data['start_date_protected'])) >=0));",
//     type: "htmlelement",
//     input: false,
//     tableView: false,
//     hideOnChildrenHidden: false,
//     dataGridLabel: false,
//     placeholder: "",
//     prefix: "",
//     customClass: "",
//     suffix: "",
//     multiple: false,
//     defaultValue: null,
//     protected: false,
//     unique: false,
//     persistent: false,
//     clearOnHide: true,
//     refreshOn: "",
//     redrawOn: "",
//     modalEdit: false,
//     labelPosition: "top",
//     description: "",
//     errorLabel: "",
//     tooltip: "",
//     hideLabel: false,
//     tabindex: "",
//     disabled: false,
//     autofocus: false,
//     dbIndex: false,
//     customDefaultValue: "",
//     calculateValue: "",
//     calculateServer: false,
//     widget: null,
//     attributes: {},
//     validateOn: "change",
//     validate: {
//       required: false,
//       custom: "",
//       customPrivate: false,
//       strictDateValidation: false,
//       multiple: false,
//       unique: false,
//     },
//     conditional: {
//       show: null,
//       when: null,
//       eq: "",
//     },
//     overlay: {
//       style: "",
//       left: "",
//       top: "",
//       width: "",
//       height: "",
//     },
//     allowCalculateOverride: false,
//     encrypted: false,
//     showCharCount: false,
//     showWordCount: false,
//     properties: {},
//     allowMultipleMasks: false,
//     tag: "p",
//     id: "eye6tas",
//   },
];
export default components;
