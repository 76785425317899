import React, { useEffect, useState } from "react";
interface Props {
  activeTab?: string;
  tabs: Array<{
    name: string;
    child: React.ReactNode | null | undefined;
  }>;
  onTabChange?: (tab: string) => void;
}
/**
 * @param { activeTab : string, tabs: Array<{ name: string; child: React.ReactNode | null | undefined; }> }
 * @returns <Tabs/>
 */
export default function Tabs({ activeTab, tabs = [], onTabChange }: Props) {
  //[]
  const active = "outline outline-1 outline-black";
  const defaultClass = "p-1 px-2 bg-blue-200 rounded-sm cursor-pointer";
  const [currentActiveTab, setCurrentActiveTab] = useState(activeTab || tabs[0]?.name);
  useEffect(() => {
    if (activeTab && activeTab !== currentActiveTab) {
      setCurrentActiveTab(activeTab);
    }
  }, [activeTab]);

  if (currentActiveTab == "reset") {
    return null;
  }

  return (
    <div className="flex gap-2 flex-col">
      <div className="flex flex-wrap gap-2" style={{
      paddingTop: "13px"
    }}>
        {tabs.map((tab) => {
          return (
            <div
              className={`${defaultClass} ${tab.name === currentActiveTab && active}`}
              onClick={() => {
                if (tab.name != currentActiveTab) {
                  onTabChange?.(tab.name);
                }
                setCurrentActiveTab(tab.name);
              }}>
              {tab.name}
            </div>
          );
        })}
      </div>
      {tabs.find(({ child, name }) => name === currentActiveTab)?.child}
    </div>
  );
}
