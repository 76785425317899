import File from "formiojs/components/file/File";
import * as _utils from "formiojs/utils/utils";
import { Formio } from "formiojs";
import * as _lodash from "lodash";
import Swal from "sweetalert2";
import { extensionTypes, specialCharacters } from "../../data/constants";
const path = require("path");

export default class FileComponent extends File {
  existingImges = [];
  constructor(component, options, data) {
    var formOptions = Formio.getPlugin("optionsPlugin");
    var customOptions = _lodash.default.merge(options, formOptions.options);
    super(component, customOptions, data);
    component.core = undefined;
    component.appId = customOptions.appId;
    component.uiUrl = customOptions.uiUrl;
    component.wrapperUrl = customOptions.wrapperUrl;
    component.fileId = customOptions.fileId;
    component.properties = customOptions.properties;
    component.deleteFile = customOptions.deleteFile;
    this.osjsCore = customOptions.core;
    this.helper = this.osjsCore.make("oxzion/restClient");
  }
  images = [];
  removeImages = [];

  getFileExtension(fileName) {
    const indexOfDot = fileName.lastIndexOf(".");
    const extension = fileName.slice(indexOfDot);
    return extension;
  }

  removeSpecialCharacters(fileName) {
    return fileName?.replaceAll(specialCharacters, "")?.replace(String.fromCharCode(91), "")?.replace(String.fromCharCode(92), "")?.replace(String.fromCharCode(93), "");
  }

  setupFileRemovalListener(fileName) {
    setTimeout(() => {
      let x = document.getElementsByClassName("fa fa-remove");
      if (x) {
        let xArray = Array.from(x);
        xArray.map((x) => {
          x?.addEventListener("click", () => {
            let indexOfFile = this.images.indexOf(fileName);
            this.images.splice(indexOfFile, 1);
          });
        });
      }
    }, 0);
  }

  updateFileUploadState(object, status, message) {
    Object.assign(object, { status, message });
  }

  isDuplicate(file, data) {
    const uploadedFileNames = data[this.component.key].map((file) => file.name); //getting all files names of the component that the file has been uploaded to
    const duplicate = uploadedFileNames.some((fileName) => fileName ===  this.removeSpecialCharacters(file.name)); //checking if there are duplicates
    return duplicate;
  }

  isValidFileExtension(file) {
    return extensionTypes.includes(this.getFileExtension(file.name).replace(".", "").toLowerCase());
  }

  isValidFileName(fileName) {
    return fileName.replaceAll(specialCharacters, "")?.replace(String.fromCharCode(91), "")?.replace(String.fromCharCode(92), "")?.replace(String.fromCharCode(93), "").length <= 0;
  }

  uploadedFileValidation(file, data) {
    const COMPONENT = this.component;
    const fileName = (0, _utils.uniqueName)(file.name, COMPONENT.fileNameTemplate, this.evalContext());
    const fileUpload = {
      originalName: file.name,
      name: fileName,
      size: file.size,
      status: "info",
      message: this.t("Starting upload"),
    };

    //checking duplicate
    if (this.isDuplicate(file, data)) {
      this.updateFileUploadState(fileUpload, "error", this.t("Duplicate File"));
      Object.assign(fileUpload, { originalName: this.removeSpecialCharacters(file.name) });
    }

    // Check file pattern
    if (COMPONENT.filePattern && !this.isValidFileExtension(file)) {
      const errorMessage = this.t(`Files with extensions ${this.getFileExtension(file.name)} are not allowed!`, { pattern: COMPONENT.filePattern });
      this.updateFileUploadState(fileUpload, "error", errorMessage);
    }

    //checking file pattern
    if (COMPONENT.filePattern && !this.validatePattern(file, COMPONENT.filePattern)) {
      const errorMessage = this.t("File is the wrong type, it must be {{ pattern }}", { pattern: COMPONENT.filePattern });
      this.updateFileUploadStatus(fileUpload, "error", errorMessage);
    }

    //checking if there is a filename after removing all special characters
    if (this.isValidFileName(fileName)) {
      const errorMessage = this.t("File name cannot only contain special characters", { pattern: COMPONENT.filePattern });
      this.updateFileUploadState(fileUpload, "error", errorMessage);
    }

    // Check file minimum size
    if (COMPONENT.fileMinSize && !this.validateMinSize(file, COMPONENT.fileMinSize)) {
      const errorMessage = this.t("File is too small, it must be at least {{ size }}", { size: COMPONENT.fileMinSize });
      this.updateFileUploadStatus(fileUpload, "error", errorMessage);
    }

    // Check file maximum size
    if (COMPONENT.fileMaxSize && !this.validateMaxSize(file, COMPONENT.fileMaxSize)) {
      const errorMessage = this.t("File is too big, it must be at most {{ size }}", { size: COMPONENT.fileMaxSize });
      this.updateFileUploadStatus(fileUpload, "error", errorMessage);
    }

    // Get a unique name for this file to keep file collisions from occurring.
    if (!this.fileService) {
      this.updateFileUploadStatus(fileUpload, "error", this.t("File Service not provided."));
    }
    return fileUpload;
  }

  //moves files from invalid to valid
  uploadFile(fileUpload, obj) {
    var index = this.statuses.indexOf(fileUpload);
    if (index !== -1) {
      //removes the file from invalid files category
      this.statuses.splice(index, 1);
    }
    if (!this.hasValue()) {
      this.dataValue = [];
    }
    this.dataValue.push(obj);
    obj.id < 1 && this?.options?.fileUploadCallback?.(obj); //checks if id is genereted in the frontend of backend
    this.redraw();
    this.triggerChange();
  }

  upload(files) {
    /**
     * @this {this.data} prev data
     * @param {files} [fileObject] files being uploded
     * @constant {this.dataValue} [attachmentObject] contans all the attachments
     */

    //this.data cant be accessed inside the forEach
    const data = this.data;

    Array.from(files).map((item) => this.images?.push(item.name));
    var _this6 = this;

    // Only allow one upload if not multiple.
    if (!this.component.multiple) {
      files = Array.prototype.slice.call(files, 0, 1);
    }
    if (this.component.storage && files && files.length) {
      // files is not really an array and does not have a forEach method, so fake it.
      Array.prototype.forEach.call(files, function (file) {
        var dir = _this6.interpolate(_this6.component.dir || "");
        const fileUpload = _this6.uploadedFileValidation(file, data);
        //adding proper funtionality to the x button when file is uploaded
        _this6.setupFileRemovalListener(file.name, this);
        _this6.statuses.push(fileUpload);
        _this6.redraw();
        if (fileUpload.status !== "error") {
          //adds event listners to x buttons so files can be removed and readded with no error
          _this6.images.map(function (fileName) {
            if (!extensionTypes.includes(fileName.split(".").pop().toLowerCase())) {
              _this6.setupFileRemovalListener(fileName, _this6);
            }
          });
          if (_this6.component.privateDownload) {
            file.private = true;
          }
          var _this6$component = _this6.component,
            storage = _this6$component.storage,
            _this6$component$opti = _this6$component.options,
            options = _this6$component$opti === void 0 ? {} : _this6$component$opti;
          var url = _this6.interpolate(_this6.component.url);
          var fileKey = _this6.component.fileKey || "file";
          if (_this6.component.storage == "url") {
            const uploadFile = {
              file: file,
              data: {
                name: _this6.removeSpecialCharacters(file.name),
                type: file.type,
              },
            };
            if (_this6?.options?.fileUploadCallback && !_this6.component.properties["attachment_save_type"]) {
              //upload after submit
              const obj = {
                randomId: Math.random(),
                size: file.size,
                ...uploadFile.data,
                uploadFile,
              };
              _this6.uploadFile(fileUpload, obj);
            } else if (_this6.component.properties["attachment_save_type"] === "onUpload") {
              //immediate upload
              _this6.helper.request("v1", _this6.component.properties["absoluteUrl"] ? url : "/app/" + _this6.component.appId + "/file/attachment", uploadFile, "fileupload").then(function (response) {
                if (response.status == "success") {
                  _this6.uploadFile(fileUpload, response.data);
                } else {
                  //fallback incase backend sends an error not handled by frontend
                  fileUpload.status = "error";
                  fileUpload.message = response.message;
                  delete fileUpload.progress;
                  _this6.redraw();
                }
              });
            }
          } else {
            fileService
              .uploadFile(
                storage,
                file,
                fileName,
                dir,
                function (evt) {
                  fileUpload.status = "progress";
                  fileUpload.progress = parseInt((100.0 * evt.loaded) / evt.total);
                  delete fileUpload.message;

                  _this6.redraw();
                },
                url,
                options,
                fileKey,
              )
              .then(function (fileInfo) {
                var index = _this6.statuses.indexOf(fileUpload);

                if (index !== -1) {
                  _this6.statuses.splice(index, 1);
                }

                fileInfo.originalName = file.name;

                if (!_this6.hasValue()) {
                  _this6.dataValue = [];
                }

                _this6.dataValue.push(fileInfo);

                _this6.redraw();

                _this6.triggerChange();
              })
              .catch(function (response) {
                fileUpload.status = "error";
                fileUpload.message = response;
                delete fileUpload.progress;

                _this6.redraw();
              });
          }
        }
      });
    }
  }
  getFile(fileInfo) {
    //doing this check to handle files that are uploded but not saved to the database
    //if fileInfo has a url it has been saved to the database
    if (fileInfo.url) {
      const { options = {} } = this.component;
      const { fileService } = this;
      if (!fileService) {
        return alert("File Service not provided");
      }
      if (this.component.privateDownload) {
        fileInfo.private = true;
      }
      fileService
        .downloadFile(fileInfo, options)
        .then((file) => {
          if (file) {
            if (["base64", "indexeddb"].includes(file.storage)) {
              download(file.url, file.originalName || file.name, file.type);
            } else {
              if (fileInfo.url && !fileInfo.url.includes(this.component.wrapperUrl + this.component.appId, 0)) {
                window.open(fileInfo.url.replace(this.component.uiUrl, this.component.wrapperUrl + this.component.appId), "_blank");
              } else {
                if (fileInfo.url) {
                  window.open(fileInfo.url, "_blank");
                } else {
                  window.open(file.url, "_blank");
                }
              }
            }
          }
        })
        .catch((response) => {
          // Is alert the best way to do this?
          // User is expecting an immediate notification due to attempting to download a file.
          alert(response);
        });
    } else {
      const fileData = fileInfo.uploadFile.file;
      const newblob = new Blob([fileData], { type: fileData.type });
      const blobUrl = URL.createObjectURL(newblob);
      window.open(blobUrl, "_blank");
    }
  }
  async deleteFile(file = {}) {
    if (this.component.properties["attachment_save_type"] === "onUpload") {
      await this.component.deleteFile(file)
    }
    let removeImages = this.removeImages;
    this.removeImages.push(file.name);
    this.images = this.images.filter(function (value, index) {
      return removeImages.indexOf(value) == -1;
    });
    if (!file.uuid) return;
    this.options?.onFileDelete?.(file);
  }

  render() {
    setTimeout(() => {
      if (document.getElementById(this.element?.id)) {
        if (!document.getElementById(this.element?.id).contains(document.querySelector('[ref="removeLink"]'))) this.images = [];
      }
    }, 500);
    return super.render();
  }
}
