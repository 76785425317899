import React from "react";
import moment from "moment";

export default function HeaderComponent(props) {
  let headerArray = [];
  const lowerCaseStatus = props.fileData.status?.toLowerCase();

  const stepDownPage = () => {
    let ev = new CustomEvent("stepDownPage", {
      detail: {},
      bubbles: true,
    });
    if (document.getElementById("navigation_" + props.appId)) {
      document
        .getElementById("navigation_" + props.appId)
        .dispatchEvent(ev);
    }
  };

  if (props.fileData?.status) {
    headerArray.push(
      <div>
        <p>Status</p> <span className="task-status"
          style={{
            backgroundColor: ["completed", "approved", "closed"].includes(
              lowerCaseStatus
            )
              ? "#A3C53A"
              : lowerCaseStatus === "in progress"
                ? "#F3BA1D"
                : [
                  "delayed",
                  "not approved",
                  "rejected",
                  "not completed",
                ].includes(lowerCaseStatus)
                  ? "#EE4424"
                  : "#3FB5E1",
          }}
        ></span>{" "}
        <p style={{ margin: 'auto' }}>{props.fileData.status}</p>
      </div>
    )
  }

  if (props.fileData?.start_date) {
    headerArray.push(
      <div>
        <p>Start Date</p> <p title={props.fileData.start_date ? props.fileData.start_date : ""}  >{ props.fileData.start_date ? moment(props.fileData.start_date).format('YYYY-MM-DD') : ""}</p>
      </div>
    )
  }
  if (props.fileData?.end_date) {
    headerArray.push(
      <div>
        <p>Due On</p> <p title={props.fileData.end_date ? props.fileData.end_date : ""}>{ props.fileData.end_date ? moment(props.fileData.end_date).format('YYYY-MM-DD') : ""}</p>
      </div>
    )
  }
  if ( props.fileData?.assignedto || props.fileData?.managerId ) {
    var imageAssigned = `${props.core.config("wrapper.url")}user/profile/${props.fileData.assignedto ? props.fileData.assignedto : props.fileData.managerId}`;
    headerArray.push(
      <div className="owner-assignee">
        Assigned To {(imageAssigned)
          ? <div title={props?.fileData?.assignedtoObj?.name ?? props?.fileData?.assignedToName } className='msg-img' style={{ background: `url(${imageAssigned})`, backgroundSize: "contain", height: "20px", width: "20px", borderRadius: "50%",cursor:"pointer" }}>
          </div> : <i className="fad fa-user owner-assignee-dp"></i>
        }
      </div>
    )
  }
  if (props.fileData?.created_by) {
    var imageOwner = props.core.config("wrapper.url") + "user/profile/" + props.fileData.ownerId;
      headerArray.push(
        <div className="owner-assignee">
          Created by {(imageOwner)
            ? <div title={props?.fileData?.createdByName ?? props?.fileData?.createdByName }className='msg-img' style={{ background: `url(${imageOwner})`, backgroundSize: "contain", height: "20px", width: "20px", borderRadius: "50%" }}>
            </div> : <i className="fad fa-user owner-assignee-dp"></i>
        }
        </div>
        
    )
  }
  // if (imageOwner) {
  // var imageOwner = props.core.config("wrapper.url") + "user/profile/" + props.fileData.ownerId;

  //   headerArray.push(
  //     <div className="owner-assignee">
  //       Owner {(imageOwner)
  //       //  ? <div className='msg-img' style={{ background: `url(${imageOwner})`, backgroundSize: "contain", height: "20px", width: "20px", borderRadius: "50%" }}></div> : <i className="fad fa-user owner-assignee-dp"></i>
  //     }
  //     </div>
  //   )
  // }
  return (
    <div className="task-header width-100">
      <div className="task-header_taskname">
        {props.entityDetails?.entity_icon ? (
          <i class={props.entityDetails?.entity_icon}></i>
        ) : props.fileData.name ? (
          props.fileData.name
            ?.trim()
            ?.split(" ")
            ?.slice(0, 2)
            ?.map((v) => v?.[0]?.toUpperCase())
            ?.join("")
        ) : (
          props.proc.metadata.name
            ?.trim()
            ?.split(" ")
            ?.slice(0, 2)
            ?.map((v) => v?.[0]?.toUpperCase())
            ?.join("")
        )}
      </div>
      <div className="task-header_info width-100">
        <div className="task-header_name" title={props.fileData.title}>
          {props.fileData.title ? props.fileData.title : props.fileData.name ? props.fileData.name : props.proc.metadata.name}
        </div>
        <div className="task-header_details">{headerArray}</div>
      </div>
    </div>
  );
}


