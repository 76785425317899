const components = [
    {
      "label": "Attachments",
      "tabindex": "8",
      "tableView": false,
      "storage": "url",
      "webcam": true,
      "fileTypes": [{
          "label": "",
          "value": ""
      }],
      "multiple": true,
      "clearOnHide": false,
      "key": "attachments",
      "type": "file",
      "url": "/file/attachment",
      "input": true,
      "placeholder": "",
      "prefix": "",
      "customClass": "",
      "suffix": "",
      "defaultValue": null,
      "protected": false,
      "unique": false,
      "persistent": true,
      "hidden": false,
      "refreshOn": "",
      "redrawOn": "",
      "modalEdit": false,
      "dataGridLabel": false,
      "labelPosition": "top",
      "description": "",
      "errorLabel": "",
      "tooltip": "",
      "hideLabel": false,
      "disabled": false,
      "autofocus": false,
      "dbIndex": false,
      "customDefaultValue": "",
      "calculateValue": "",
      "calculateServer": false,
      "widget": null,
      "attributes": {},
      "validateOn": "change",
      "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false
      },
      "conditional": {
          "show": null,
          "when": null,
          "eq": ""
      },
      "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
      },
      "allowCalculateOverride": false,
      "encrypted": false,
      "showCharCount": false,
      "showWordCount": false,
      "properties": {},
      "allowMultipleMasks": false,
      "image": false,
      "privateDownload": false,
      "imageSize": "200",
      "filePattern": "*",
      "fileMinSize": "0KB",
      "fileMaxSize": "1GB",
      "uploadOnly": false,
      "id": "en29f7r"
  }
  ];
  export default components;
  