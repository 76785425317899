import React from "react";
import { v4 } from "uuid";
import PageContent from "./PageContent";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import "./Styles/TabStyles.scss";
class TabSegment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabContent: [],
      tabs: this.props.tabs ? this.props.tabs : [],
      activeTab: 0,
    };
  }

  componentDidMount() {
    this.prepareTabs();
  }

  prepareTabs = () => {
    let selectedTabIndex = 0;
    if (this.props.tabs.length > 1) {
      if (this.props.selectedTab) {
        this.props.tabs?.map((each, index) => {
          if (each.name === this.props.selectedTab) {
            selectedTabIndex = index;
          }
        });
      }
      let tabContent = [];
      this.props.tabs.map((item, i) => {
        let uuidv4 = v4();
        let tabContentKey = (item.uuid ? item.uuid : uuidv4) + "_tab";
        let fileData = item.fileData ? item.fileData : {};
        tabContent.push({
          name: item.name,
          uuid: tabContentKey,
          content: (
            <PageContent
              key={tabContentKey}
              notif={this.props.notif}
              config={this.props.config}
              proc={this.props.proc}
              isTab="true"
              appId={this.props.appId}
              parentPage={this.props.pageId}
              fileData={fileData}
              currentRow={this.props.currentRow ?? {}}
              pageContent={item.content}
              pageId={item.pageId}
              fileId={this.props.fileId}
              core={this.props.core}
            />
          ),
        });
      });
      this.setState({
        tabContent: tabContent,
        activeTab: selectedTabIndex,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.tabs !== this.props.tabs) {
      this.setState({ tabs: this.props.tabs });
      this.prepareTabs();
    } else if (prevProps.selectedTab !== this.props.selectedTab) this.prepareTabs();
  }

  handleSelect = (event) => {
    this.setState({
      activeTab: event.selected,
    });
  };

  render() {
    if (this.state.tabs?.length == 1) {
      return (
        <PageContent
          key={this.state.tabs[0].uuid}
          notif={this.props.notif}
          config={this.props.config}
          proc={this.props.proc}
          appId={this.props.appId}
          fileId={this.props.fileId}
          pageContent={this.state.tabs[0].content ? this.state.tabs[0].content : null}
          currentRow={this.props.currentRow ?? {}}
          core={this.props.core}
        />
      );
    } else if (this.state.tabs?.length > 1) {
      return (
        <div className="tabs-container">
          <TabStrip selected={this.state.activeTab} onSelect={(event) => this.handleSelect(event)}>
            {this.state.tabContent?.map((eachTab, index) => {
              return (
                <TabStripTab key={index} title={eachTab.name} style={{ color: "red" }}>
                  <div id={eachTab.uuid} className={`tab_${eachTab.uuid} active_tabstrip`}>
                    {eachTab.content}
                  </div>
                </TabStripTab>
              );
            })}
          </TabStrip>
        </div>
      );
    } else {
      return <div>No Content to Display</div>;
    }
  }
}

export default TabSegment;