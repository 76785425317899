import Select from "formiojs/components/select/Select";
import * as _utils from "formiojs/utils/utils";
import * as _Formio from "formiojs/Formio";
import * as _lodash from "lodash";
import { Formio } from "formiojs";
import * as _nativePromiseOnly from "native-promise-only";
import helpers from "../../../helpers";

export default class SelectComponent extends Select {
  constructor(component, options, data) {
    var formOptions = Formio.getPlugin("optionsPlugin");
    var customOptions = _lodash.default.merge(options, formOptions.options);
    if (customOptions.core == null || customOptions.core == undefined) {
    }
    super(component, customOptions, data);
    component.core = undefined;
    component.uiUrl = customOptions.uiUrl;
    component.wrapperUrl = customOptions.wrapperUrl;
    component.appId = customOptions.appId;
    component.formId = customOptions.formId;
    this.isRecordsFetchedByUuid = false;
    this.form = this.getRoot();
    this.osjsCore = customOptions.core;
    this.cache = {};
  }

  catchErrorFunct(err) {
    if (this.isInfiniteScrollProvided) {
      this.setItems([]);
      this.disableInfiniteScroll();
    }

    this.isScrollLoading = false;
    this.loading = false;

    this.itemsLoadedResolve();

    this.emit("componentError", {
      component: this.component,
      message: err.toString(),
    });

    console.warn("Unable to load resources for ".concat(this.key));
  }

  async fetchRecordsbyUuid(_this3) {
    if (!_this3.isRecordsFetchedByUuid && _this3.getValue()) {
      let valuesArray = [];

      if (Array.isArray(_this3.getValue()) && _this3.getValue()?.length > 0) {
        _this3.getValue()?.map((item) => {
          if (item?.hasOwnProperty("uuid")) {
            valuesArray.push(item.uuid);
          }
        });
      } else if (_this3.getValue()?.hasOwnProperty("uuid")) {
        valuesArray.push(_this3.getValue()?.uuid ?? "");
      } else if (typeof _this3.getValue() === "string" && _this3.getValue() !== "") {
        valuesArray.push(_this3.getValue());
      }
      let filters = valuesArray.map((value) => {
        return {
          field:
            _this3.component.valueProperty !== ""
              ? _this3.component.valueProperty
              : "uuid",
          operator: "contains",
          value: value,
        };
      });     

        if (filters?.length !== 0) {
          var url = _this3.component.data.url;

          var limit = this.component.limit || 10;
          var skip = 0;

          url = helpers.ParameterHandler.replaceParams(
            _this3.component.appId,
            url,
            _this3.component
          );
          url = _this3.interpolate(url, {
            formioBase: _this3.component.wrapperUrl,
          });

          let dataState = {
            filter: {
              logic: filters?.length > 1 ? "or" : "and",
              filters: filters,
            },
            take: limit,
            skip: skip,
          };

          let bodyParams=this.component?.sendfilterParamsInBody?{ filter:"[" + JSON.stringify(dataState) + "]"}:{};
          let method= this.component?.data.method?this.component?.data.method:"GET";
          if (!url.includes("https") && !url.includes("filter") && !this.component?.sendfilterParamsInBody) {
            url += (url.includes("?") ? "&" : "?" ) + "filter=[" + JSON.stringify(dataState) + "]"; 
          }
          var helper = this.osjsCore.make("oxzion/restClient");
          var resp = await helper
            .request(
              "v1",
              _this3.component.properties["absoluteUrl"]
                ? url
                : "/app/" + _this3.component.appId + url,
              bodyParams,
              method
            )
            .then((response) => {
              _this3.loading = false;
              if (response.status == "success") {
                _this3.isRecordsFetchedByUuid = true;
                return response.data;
              }
            });
          return resp;
        }
        return false;
    }
  }

  async loadItems(url, search, headers, options, method, body) {
    var _this3 = this;
    options = options || {}; // See if they have not met the minimum search requirements.

    var minSearch = parseInt(this.component.minSearch, 10);

    if (
      this.component.searchField &&
      minSearch > 0 &&
      (!search || search.length < minSearch)
    ) {
      // Set empty items.
      return this.setItems([]);
    } // Ensure we have a method and remove any body if method is get

    if (this.cache[`${this.component.key}-${search || ""}`]?.length > 0) {
      return this.setItems(this.cache[`${this.component.key}-${search || ""}`]);
    }

    method = method || "GET";

    if (method.toUpperCase() === "GET") {
      body = null;
    }
    var limit = this.component.limit || 10;
    var skip = this.isScrollLoading ? this.selectOptions.length : 0;
    var query =
      this.component.dataSrc === "url"
        ? {}
        : {
            limit: limit,
            skip: skip,
          }; // Allow for url interpolation.
    url = helpers.ParameterHandler.replaceParams(_this3.component.appId, url, _this3.component);
    let rowObj = {};
    if (typeof _this3.row === 'string') {
      let substring = ".";
      let lastIndex =  _this3.path?.lastIndexOf(substring);

      if (lastIndex !== -1) {
        let firstPart = _this3.path.substring(0, lastIndex);
        rowObj = _lodash.get(this.form.data, firstPart)
    }
    }
    url = this.interpolate(url, {
      row: rowObj,
      formioBase: this.component.wrapperUrl,
      search: search,
      limit: limit,
      skip: skip,
      page: Math.abs(Math.floor(skip / limit)),
    }); // Add search capability.

    if (this.component.searchField && search) {
      if (Array.isArray(search)) {
        query["".concat(this.component.searchField)] = search.join(",");
      } else {
        query["".concat(this.component.searchField)] = search;
      }
    } // If they wish to return only some fields.

    if (this.component.selectFields) {
      query.select = this.component.selectFields;
    } // Add sort capability

    if (this.component.sort) {
      query.sort = this.component.sort;
    }

    // if (!_lodash.default.isEmpty(query)) {
    //   // Add the query string.
    //   url += (!url.includes('?') ? '?' : '&') + _Formio.default.serialize(query, function (item) {
    //     return _this3.interpolate(item);
    //   });
    // } // Add filter capability

    if (this.component.filter) {
      url +=
        (!url.includes("?") ? "?" : "&") +
        this.interpolate(this.component.filter);
    } // Make the request.

    let dataState = {
      filter: {
        logic: "and",
        filters: [
          {
            field: this.component.searchField,
            operator: search ? "contains" : "isnotempty",
            value: search ?? "",
          },
        ],
      },
      sort: [{ dir: "asc", field: this.component.searchField }],
      take: limit,
      skip: skip,
    };
    let additionalFilters = headers.map;
    if (Object.keys(additionalFilters).length > 0) {
      Object.keys(additionalFilters).map((item) => {
        let value = this.form.data[item];
        if (value != "" && value != null && value != undefined) {
          dataState.filter.filters.push({
            field: additionalFilters[item],
            operator: "contains",
            value: value,
          });
        }
      });
    }
    if (!url.includes("https") && !url.includes("filter")) {
      url += (url.includes("?") ? "&" : "?" ) + "filter=[" + JSON.stringify(dataState) + "]"; 
    }

    options.header = headers;
    this.loading = true;
    if (url.includes("https")) {
      fetch(url)
        .then((response) => {
          if (response.status == "200") {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          _this3.cache[`${_this3.component.key}-${search || ""}`] =
            data[_this3.component.selectValues];
          _this3.setItems(data[_this3.component.selectValues], !!search);
        })
        .catch((error) => {
          this.catchErrorFunct(error);
        });
    } else {
      var helper = this.osjsCore.make("oxzion/restClient");
      helper
        .request(
          "v1",
          _this3.component.properties["absoluteUrl"]
            ? url
            : "/app/" + _this3.component.appId + url,
          body,
          method
        )
        .then(async function (response) {
          _this3.loading = false;
          if (response.status == "success") {
            const recordsByUuid = await _this3.fetchRecordsbyUuid(_this3);
            if (recordsByUuid) {
              let array = await response.data.concat(recordsByUuid);
              // remove duplicate items
              const uniqueItems = array.filter(
                (obj, index) =>
                  array.findIndex((item) => item.uuid === obj.uuid) === index
              );
              response.data = uniqueItems;
            }
            if (response.data?.optionId) {
              _this3.component.optionId = response.data.optionId;
            }
            _this3.cache[`${_this3.component.key}-${search || ""}`] =
              response.data;
            response.data
              ? _this3.setItems(response.data, !!search)
              : _this3.setItems(response, !!search);
          } else {
            _this3.setItems([]);
          }
        })
        .catch((error) => {
          this.catchErrorFunct(error);
        });
    }
  }
  render() {
    return super.render();
  }
  updateItems(searchInput, forceUpdate) {
    if (!this.component.data) {
      console.warn(
        `Select component ${this.key} does not have data configuration.`
      );
      this.itemsLoadedResolve();
      return;
    }

    // Only load the data if it is visible.
    if (!this.visible) {
      this.itemsLoadedResolve();
      return;
    }

    switch (this.component.dataSrc) {
      case "values":
        this.setItems(this.component.data.values);
        break;
      case "json":
        this.setItems(this.component.data.json);
        break;
      case "custom":
        this.updateCustomItems();
        break;
      case "resource": {
        // If there is no resource, or we are lazyLoading, wait until active.
        if (!this.component.data.resource || (!forceUpdate && !this.active)) {
          this.itemsLoadedResolve();
          return;
        }

        let resourceUrl = this.options.formio
          ? this.options.formio.formsUrl
          : `${Formio.getProjectUrl()}/form`;
        resourceUrl += `/${this.component.data.resource}/submission`;

        if (
          forceUpdate ||
          this.additionalResourcesAvailable ||
          !this.serverCount
        ) {
          try {
            this.loadItems(resourceUrl, searchInput, this.requestHeaders);
          } catch (err) {
            console.warn(`Unable to load resources for ${this.key}`);
          }
        } else {
          this.setItems(this.downloadedResources);
        }
        break;
      }
      case "url": {
        if (!forceUpdate && !this.active && !this.calculatedValue) {
          // If we are lazyLoading, wait until activated.
          this.itemsLoadedResolve();
          return;
        }
        var url = this.component.data.url;
        let method;
        let body;

        if (!this.component.data.method) {
          method = "GET";
        } else {
          method = this.component.data.method;
          if (method.toUpperCase() === "POST") {
            body = this.component.data.body;
          } else {
            body = null;
          }
        }
        const options = this.component.authenticate ? {} : { noToken: true };
        this.loadItems(
          url,
          searchInput,
          this.requestHeaders,
          options,
          method,
          body
        );
        break;
      }
      case "indexeddb": {
        if (typeof window === "undefined") {
          return;
        }

        if (!window.indexedDB) {
          window.alert(
            "Your browser doesn't support current version of indexedDB"
          );
        }

        if (
          this.component.indexeddb &&
          this.component.indexeddb.database &&
          this.component.indexeddb.table
        ) {
          const request = window.indexedDB.open(
            this.component.indexeddb.database
          );

          request.onupgradeneeded = (event) => {
            if (this.component.customOptions) {
              const db = event.target.result;
              const objectStore = db.createObjectStore(
                this.component.indexeddb.table,
                { keyPath: "myKey", autoIncrement: true }
              );
              objectStore.transaction.oncomplete = () => {
                const transaction = db.transaction(
                  this.component.indexeddb.table,
                  "readwrite"
                );
                this.component.customOptions.forEach((item) => {
                  transaction
                    .objectStore(this.component.indexeddb.table)
                    .put(item);
                });
              };
            }
          };

          request.onerror = () => {
            window.alert(request.errorCode);
          };

          request.onsuccess = (event) => {
            const db = event.target.result;
            const transaction = db.transaction(
              this.component.indexeddb.table,
              "readwrite"
            );
            const objectStore = transaction.objectStore(
              this.component.indexeddb.table
            );
            new NativePromise((resolve) => {
              const responseItems = [];
              objectStore.getAll().onsuccess = (event) => {
                event.target.result.forEach((item) => {
                  responseItems.push(item);
                });
                resolve(responseItems);
              };
            }).then((items) => {
              if (!_.isEmpty(this.component.indexeddb.filter)) {
                items = _.filter(items, this.component.indexeddb.filter);
              }
              this.setItems(items);
            });
          };
        }
      }
    }
  }
}
