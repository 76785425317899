import React from "react";

import "./P.scss";

const P = ({ text, onClicked, id }) => {
  return (
    <p className="StylesGuideP" id={id} onClicked={onClicked}>
      {text}
    </p>
  );
};

export default P;
