import React from "react";

import "./PrimaryButton.scss";

const PrimaryButton = ({ text, onClicked, id, disabled }) => {
  return (
    <button
      onClick={disabled ? () => console.log("diasbled") : onClicked}
      className={disabled ? "disabled" : "primaryButton"}
      id={id}
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
