import React from "react";

import "./H1.scss";

const H1 = ({ text, onClicked, id }) => {
  return (
    <h1 className="StylesGuideH1" id={id} onClicked={onClicked}>
      {text}
    </h1>
  );
};

export default H1;
