const components = [
    {
                        "label": "Zip Code",
                        "tabindex": "12",
                        "autocomplete": "off",
                        "tableView": true,
                        "clearOnHide": false,
                        "validate": {
                            "custom": "if(data['non_us_zip'] && data['non_us_zip'] != '')\n{\n  if(data['non_us_zip'].length <= 9)\n  { \n    valid = (data['non_us_zip'].match(/^[0-9]+$/)) ? true : 'Please enter a valid zip code.'; \n  }    \n  else\n  {\n    valid = (data['non_us_zip'].length > 9) ? 'Please enter a valid zip code,' : true; \n  } \n}\n\n"
                        },
                        "key": "non_us_zip",
                        "conditional": {
                            "show": false,
                            "when": "country",
                            "eq": "United States of America"
                        },
                        "attributes": {
                            "style": "text-align:right"
                        },
                        "type": "textfield",
                        "input": true,
                        "hideOnChildrenHidden": false
                    }
    ];
    export default components;
    