const components = [
	{
		"label": "Job Title",
		"tooltip": "Please enter the employee job title.",
		"tabindex": "5",
		"autocomplete": "off",
		"tableView": true,
		"validate": {
			"required": true,
			"customMessage": "Please enter the employee job title."
		},
		"key": "designation",
		"type": "textfield",
		"input": true,
		"hideOnChildrenHidden": false
	}
];
export default components;
