import React from "react";
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
// Be sure to include styles at some point, probably during your bootstraping
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import Navigation from "./Navigation";
import "./public/css/LeftMenuTemplate.scss";
import BreadCrumbs from "./BreadCrumbs";

export default class LeftMenuTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.core = this.props.core;
    this.appId = this.props.appId;
    this.params = this.props.params;
    this.proc = this.props.proc;
    this.config = this.props.config;
    this.keepExpanded = this.props.keepExpanded;
    this.navigation = React.createRef();
    this.appNavigationDiv = "navigation_" + this.appId;
    this.state = {
      menus: this.props.menus ? this.props.menus : [],
      selected: "",
      expanded: this.keepExpanded ? true : false,
      customActions: [],
    };
    this.setCustomActions = this.setCustomActions.bind(this);
    this.updateState = this.updateState.bind(this);
    this.leftMenuTemplateId = `left-navigation-${this.appId}`;
  }

  menuSelect = (data) => {
    // this.state.selected.page_id == data.selected.page_id ? this.navigation.current.stepDownPage() : this.setState({ ...data });
    // if (this.state.selected.page_id == data.selected.page_id) {
    //   // this.setState({ ...data });
    //   this.navigation.current.stepDownPage();
    // } else {
    //   this.setState({ ...data });
    //   document.getElementById(this.appNavigationDiv).dispatchEvent(new CustomEvent("clearBreadCrumbs"));
    // }

    if (this.state.selected.page_id !== data.selected.page_id) {
      // this.setState({ ...data });
      this.setState({ ...data });
      document.getElementById(this.appNavigationDiv).dispatchEvent(new CustomEvent("clearBreadCrumbs"));
    }
  };

  updateState = (data) => {
    this.setState({ ...data });
  };

  onSelect(selected) {
    this.navigation.current.reloadExistingPage();
    if (selected?.page_id === this.state.selected?.page_id) return;
    this.navigation.current.resetPageCustomActions();
    this.setState({
      selected: selected,
      expanded: this.keepExpanded ? true : false,
    });
  }

  setCustomActions(customActions) {
    // Update the state only if customActions have changed to prevent re-renders
    if (JSON.stringify(customActions) != JSON.stringify(this.state.customActions)) {
      this.setState({
        customActions,
      });
    }
  }

  render() {
    const { expanded, selected } = this.state;
    return (
      <div className={"LeftMenuTemplate" + (this.props.proc.metadata.hideMenu ? " hideMenu" : "")} id={this.leftMenuTemplateId}>
        <SideNav onSelect={(i) => this.menuSelect({ selected: i })} onToggle={(i) => this.updateState({ expanded: i })} expanded={expanded}>
          {this.keepExpanded ? null : <SideNav.Toggle />}
          <SideNav.Nav selected={selected}>
            {this.state.menus && this.state.menus.length > 0 ? (
              this.state.menus.map((menuitem, index) => {
                return (
                  <NavItem eventKey={menuitem} key={index} onClick={(i) => (menuitem.submenu ? this.menuSelect({ selected: menuitem.submenu[0] }) : null)} title={menuitem.name}>
                    <NavIcon>
                      {/* <abbr title={menuitem.name} style={{ cursor: "pointer" }}> */}
                      <i className={menuitem.icon} name={menuitem.name} style={{ fontSize: "1.5em", verticalAlign: "middle", cursor: "pointer" }} />
                      {/* </abbr> */}
                    </NavIcon>
                    <NavText
                      style={{ paddingRight: 32 }}
                      name={menuitem.name}
                      // title={menuitem.name}
                    >
                      {menuitem.name.length > 18 ? menuitem.name.substring(0, 18) + "..." : menuitem.name}
                    </NavText>
                    {menuitem.submenu
                      ? menuitem.submenu.map((subMenu, index2) => {
                          return (
                            <NavItem eventKey={subMenu} key={`${Math.random()}_${Math.random()}`} expanded={true} title={subMenu.name}>
                              <NavIcon>
                                {/* <abbr
                                  title={subMenu.name}
                                  style={{ cursor: "pointer" }}
                                > */}
                                <i
                                  className={subMenu.icon}
                                  name={subMenu.name}
                                  style={{
                                    fontSize: "1.5em",
                                    verticalAlign: "middle",
                                    cursor: "pointer",
                                  }}
                                />
                                {/* </abbr> */}
                              </NavIcon>
                              <NavText
                                style={{ paddingRight: 32 }}
                                name={subMenu.name}
                                // title={subMenu.name}
                              >
                                {subMenu.name.length > 18 ? subMenu.name.substring(0, 18) + "..." : subMenu.name}
                              </NavText>
                            </NavItem>
                          );
                        })
                      : null}
                  </NavItem>
                );
              })
            ) : (
              <div></div>
            )}
          </SideNav.Nav>
        </SideNav>
        <div className="NavigationContainer">
          <div id="NavigationHeaderContainer_tempId" className="NavigationHeaderContainer">
            <BreadCrumbs core={this.core} appNavigationDiv={this.appNavigationDiv} />
            <div id="customActionsContains_tempId" className="customActionsContainer dash-manager-buttons">{this.state.customActions}</div>
          </div>
          <Navigation
            ref={this.navigation}
            setCustomActions={this.setCustomActions}
            core={this.core}
            params={this.params}
            config={this.config}
            menus={this.state.menus}
            menuLoad={(i) => this.updateState({ menus: i })}
            selectLoad={(i) => this.updateState({ selected: i })}
            onSelect={this.onSelect}
            appId={this.appId}
            proc={this.proc}
            selected={this.state.selected}
          />
        </div>
      </div>
    );
  }
}
