import React from "react";

import "./H2.scss";

const H2 = ({ text, onClicked, id }) => {
  return (
    <h2 className="StylesGuideH2" onClick={onClicked} id={id}>
      {text}
    </h2>
  );
};

export default H2;
