import React from "react";
import "./CheckBox.scss";

const CheckBox = ({ id, text, disabled, value, onChanged }) => {
  return (
    <div className="CheckBox">
      <input
        type="checkbox"
        name=""
        id={id}
        disabled={disabled}
        value={value}
        onChange={onChanged}
      />
      <label htmlFor={id}>{text}</label>
    </div>
  );
};

export default CheckBox;
