import React, { Fragment, useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Slider, SliderLabel } from "@progress/kendo-react-inputs";
import {
  GoogleMap,
  useJsApiLoader,
  Circle,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { Container } from "react-bootstrap";
import Requests from "../../Requests";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyC3U9SDLkMeTovczI9CFZJrj6hZNS37ThA");

const containerStyle = {
  width: "auto",
  height: "70vh",
};

const options = {
  strokeColor: "#FF0000",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#FF0000",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};
const libraries = ["places"];

export default function CustomMapComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC3U9SDLkMeTovczI9CFZJrj6hZNS37ThA",
    libraries,
  });
  const [ddstate, drpdwnstate] = useState(null);
  const [marker, setMarker] = useState(null);
  const [radius, setRadius] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [place, setPlace] = useState([]);
  const [lat, setLat] = useState(parseFloat(0));
  const [log, setLog] = useState(parseFloat(0));
  const [zoom, setZoom] = useState(20);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPlace, setSelectedPlace] = useState();
  const [selectedPlaceasOption, setSelectedPlaceasOption] = useState();

  useEffect(() => {
    setLoading(true);
    let url =
      "/app/ff1ecbb7-3a45-4966-b38c-bf203f171423/command/delegate/GetContactAddress";
    Requests.doRestRequest(
      props.core,
      url,
      {},
      "get",
      function (response) {
        const places = [];
        response.addressData.forEach(function (item, index) {
          Geocode.fromAddress(item).then(
            (addr) => {
              const { lat, lng } = addr.results[0].geometry.location;
              let address = {
                id: index + 1,
                name: addr.results[0].formatted_address,
                latitude: lat,
                longitude: lng,
              };
              places.push(address);
              places.reverse();
              setPlace(places);
              setMarkers(places);
              setOptions(
                places.map((p) => {
                  return p.name;
                })
              );
              setLoading(false);

              // set default location

              setLat(address.latitude);
              setLog(address.longitude);
              setSelectedPlaceasOption(address.name)
              // set default location
            },
            (error) => {
              console.error("Geocoding error", error);
            }
          );
        });
      },
      function (error) {
        console.log("error " + error);
      }
    );
  }, []);

  const handleChange = (e) => {
    place.map((i) => {
      if (e.value === i.name) {
        setLat(i.latitude);
        setLog(i.longitude);
      }
    });
    setSelectedPlace(e.value);
  };

  const center = {
    lat: lat,
    lng: log,
  };

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleChangeRadius = () => {
    if (radius > 100) {
      setZoom(2);
    } else if (radius === null || radius === undefined) {
      setZoom(20);
    } else if (radius >= 100) {
      setZoom(5);
    } else if (radius >= 75) {
      setZoom(6);
    } else if (radius >= 50) {
      setZoom(8);
    } else if (radius >= 25) {
      setZoom(10);
    } else if (radius >= 1) {
      setZoom(12);
    } else {
      setZoom(20);
    }
  };

  useEffect(() => { }, [markers]);

  return isLoaded ? (
    <Container fluid>
      <div className="mt-4">
          <span className="pr-4">Select Location:</span>
          <DropDownList
            data={place.map((item) => {
              return item.name
            })}
            onChange={handleChange}
            style={{ width: "25vw" }}
            defaultValue={selectedPlaceasOption}
          />
        <div className="mt-4">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onLoad={onMapLoad}
          >
            <Circle
              center={{
                lat: lat,
                lng: log,
              }}
              radius={parseFloat(radius * 1000)}
              options={{ fillColor: "red", strokeColor: "red" }}
            />

            {markers.map((place) => (
              <>
                <Marker
                  key={place.id}
                  zoom={8}
                  options={{ fillColor: "blue", strokeColor: "blue" }}
                  position={{ lat: place.latitude, lng: place.longitude }}
                  onMouseover={markers}
                  onClick={() => {
                    setMarker(place);
                  }}
                />
              </>
            ))}
            {marker ? (
              <InfoWindow
                position={{ lat: marker.latitude, lng: marker.longitude }}
              >
                <>
                  <p>{marker.name}</p>
                  <p>Latitude:{marker.latitude}</p>
                  <p>Longitude:{marker.longitude}</p>
                </>
              </InfoWindow>
            ) : null}
          </GoogleMap>
        </div>
        <div class="mt-3 float-right">
          <Slider
            style={{ width: "25vw" }}
            buttons={true}
            step={25}
            defaultValue={1}
            min={0}
            max={100}
            onChange={(e) => {
              setRadius(e.value);
              handleChangeRadius();
            }}
          >
            {[0,25, 50, 75, 100].map((i) => (
              <SliderLabel
                title={i.toString()}
                key={i}
                position={i}
              >
                {i} Miles
              </SliderLabel>
            ))}
          </Slider>
        </div>
      </div>
    </Container>
  ) : (
    <></>
  );
}