import React from "react";
import merge from "deepmerge";
import moment from "moment";
import html2pdf from 'html2pdf.js'
import { Button } from "@progress/kendo-react-buttons";

class RenderButtons extends React.Component {
  constructor(props) {
    super(props);
    this.headerRender=false;
    this.core = this.props.core;
    this.appId = this.props.appId;
    this.pageId = this.props.pageId;
    this.fileData = this.props.currentRow;
    this.appNavigationDiv = "navigation_" + this.appId;
    this.userProfile = this.props.core
      ? this.props.core.make("oxzion/profile").get().key
      : undefined;
    this.state = {
      buttonList: this.props.content?.buttonList
        ? this.props.content.buttonList
        : [],
      backPresent:false,
    };
  }

  componentDidMount() {
    document
      .getElementById(this.appNavigationDiv)
      .addEventListener("addButton", this.addButton.bind(this), false);
  }

  printToPDF = (printAreaHTMLSelector) => {
    let appName = this.props.proc.metadata?.name;
    let element = document.querySelector(printAreaHTMLSelector);
    if (element) {
      this.props.notif.current.notify(
        "In Progress",
        "Please wait, PDF file will download automatically.",
        "success",
        "bottom-right",
        10000
      );
      // Highlighting selected element
      element.classList.add("print-html-area-styles");
      let opt = {
        margin: [0.2, 0.5, 0.2, 0.5], //top,left,bottom,right
        filename: appName + Date.now() + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      // Converting html element into pdf
      html2pdf().set(opt).from(element).save().then(()=> element.classList.remove("print-html-area-styles"));
    } else {
      this.props.notif.current.notify(
        "Failed",
        "PDF file download has been failed due to invalid element",
        "danger",
        "bottom-right",
        10000
      );
    }
  };

  createTiles = () => {
    let buttonList = [];
    var rowData = this.fileData;
    this.state.buttonList.map((currentValue, index) => {
      var showButton;
      if (currentValue.rule) {
        const profile = this.userProfile; //for eval
        var string = this.replaceParams(currentValue.rule, rowData);
        var _moment = moment;
        string = string.replace(/moment/g, "_moment");
        showButton = eval(string);
      } else {
        showButton = true;
      }
      var copyPageContent = [];
      var that = this;
      if (currentValue.details && currentValue.details.length > 0) {
        currentValue.details.every(async (item, index) => {
          if (item.params && item.params.page_id) {
            copyPageContent.pageId = item.params.page_id;
          } else if (item.pageId) {
            copyPageContent.pageId = item.page_id;
          } else {
            var pageContentObj = item;
            pageContentObj = that.replaceParams(item, rowData);
            copyPageContent.push(pageContentObj);
          }
        });
      }
      var pageDetails = {
        title: currentValue.name,
        pageContent: copyPageContent,
        pageId: currentValue.pageId,
        icon: currentValue.icon,
        parentPage: this.pageId,
      };
      if (showButton) {
        const isExportPdf = currentValue?.details?.find(
          (detail) => detail?.type === "exportPdf"
        );
        const isBack = currentValue?.details?.find(
          (detail) => detail?.type === "back"
        );
        this.headerRender = isBack ? true : false;
        const customClass = currentValue.className
          ? currentValue.className
          : null;
        buttonList.push(
          <Button 
            title={currentValue.details?.[0]?.type}
            key={index}
            className={"btn btn-primary"+" rounded-circle "+customClass}
            style={this.headerRender? { position: 'absolute', zIndex: 3 } : {}}
            onClick={() => {
              if (currentValue.type === "shareLink") {
                this.props.notif.current.notify(
                  "",
                  "Link has been copied to clipboard",
                  "success",
                  undefined,
                  2000
                );
                this.core
                  .make("oxzion/link")
                  .copyToClipboard(
                    this.core.config("ui.url") +
                      "?app=" +
                      this.props.proc.metadata.name +
                      "&page=" +
                      currentValue.pageId
                  );
              } else if (currentValue.details?.[0]?.type === "Print To PDF") {
                this.printToPDF(currentValue.details?.[0]?.printAreaHTMLSelector);
              } else {
                let p_ev = new CustomEvent("addPage", {
                  detail: pageDetails,
                  bubbles: true,
                });
                document
                  .getElementById(this.appNavigationDiv)
                  .dispatchEvent(
                    currentValue.type == "stepDownPage" || isBack
                      ? new CustomEvent("stepDownPage")
                      : isExportPdf
                      ? new CustomEvent("exportPdf")
                      : p_ev
                  );
              }
            }}
          >
            {currentValue.icon ? (
              <i className={currentValue.icon} title={currentValue.name}></i>
            ) : (
              currentValue.name
            )}
          </Button>
        );
      }
    });
    return buttonList;
  };

  addButton(event) {
    this.state.buttonList.find((item) => item.type == event.detail[0].type)
      ? null
      : this.setState({
          buttonList: [...this.state.buttonList, ...event.detail],
        });
  }

  replaceParams(route) {
    var finalParams = merge(this.fileData ? this.fileData : {}, {
      current_date: moment().format("YYYY-MM-DD"),
      appId: this.appId,
    });
    if (typeof route == "object") {
      var final_route = JSON.parse(JSON.stringify(route));
      Object.keys(route).map((item) => {
        if (/\{\{.*?\}\}/g.test(route[item])) {
          if (finalParams[item]) {
            final_route[item] = finalParams[item];
          } else {
            if (item == "appId") {
              final_route[item] = this.appId;
            } else {
              final_route[item] = route[item];
            }
          }
        } else {
          final_route[item] = route[item];
        }
      });
      return final_route;
    } else {
      var regex = /\{\{.*?\}\}/g;
      let m;
      var matches = [];
      do {
        m = regex.exec(route);
        if (m) {
          if (m.index === regex.lastIndex) {
            regex.lastIndex++;
          }
          // The result can be accessed through the `m`-variable.
          matches.push(m);
        }
      } while (m);
      matches.forEach((match, groupIndex) => {
        var param = match[0].replace("{{", "");
        param = param.replace("}}", "");
        if (finalParams[param] != undefined) {
          route = route.replace(match[0], finalParams[param]);
        } else {
          route = route.replace(match[0], null);
        }
      });
      return route;
    }
  }
  updateActionHandler(details, rowData) {
    var that = this;
    return new Promise((resolve) => {
      var queryRoute = that.replaceParams(details.params.url, rowData);
      that.updateCall(queryRoute, rowData).then((response) => {
        that.setState({
          showLoader: false,
        });
        resolve(response);
      });
    });
  }
  render() {
    return this.headerRender ? (
      this.createTiles()
    ) : (
      <div
        className={
          " dashboard-header-container dash-manager-buttons w-100 text-right"
        }
      >
        {this.createTiles()}
        {this.props.name && (
          <div className="dashboard-label-text">
            <div id="dashboard-rollup-button" class="dashboard-rollup-button">
              {this.props.name}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default RenderButtons;
