import React from "react";
import { EventListeners } from "../../interfaces";

/**
 * @param any core
 * @param string appId
 * @param object fileData
 * @returns <CommonHeader/>
 */
export default class CommonHeader extends React.Component<{ core; fileData; appId }, { fileData: any }> {
  constructor(props) {
    super(props);
    this.state = {
      fileData: props.fileData,
    };
  }
  render() {
    return this.state.fileData && <GetTaskHeader fileData={this.state.fileData} core={this.props.core} appId={this.props.appId} />;
  }
}
function GetTaskHeader({ fileData, core, appId }) {
  const {
    title,
    name,
    // data: {
    status,
    start_date,
    next_action_date,
    end_date,
    username,
    assignedto,
    managerId,
    ownerid,
    // },
    ownerId,
  } = fileData || {};
  var imageAssigned = `${core.config("wrapper.url")}user/profile/${managerId ? managerId : assignedto}`;
  var imageOwner = core.config("wrapper.url") + "user/profile/" + ownerId;
  const goBack = () => {
    document.getElementById(`navigation_${appId}`)?.dispatchEvent?.(
      new CustomEvent(EventListeners.STEP_DOWN_PAGE, {
        detail: {},
        bubbles: true,
      })
    );
  };
  let createdDate = start_date;
  const lowerCaseStatus = status?.toLowerCase();
  return (
    <div className="task-header width-100">
      <i className="fa fa-arrow-from-left go-back" onClick={goBack}></i>
      <div className="task-header_taskname">
        {(title || name)
          ?.trim()
          ?.split(" ")
          ?.slice(0, 2)
          ?.map((v) => v?.[0]?.toUpperCase())
          ?.join("")}
      </div>
      <div className="task-header_info width-100">
        <div className="task-header_name" title={title}>
          {title || name}
        </div>
        <div className="task-header_details">
          <div>
            <p>Status</p>{" "}
            <span
              className="task-status"
              style={{
                backgroundColor: ["completed", "approved", "closed"].includes(lowerCaseStatus) ? "#A3C53A" : lowerCaseStatus === "in progress" ? "#F3BA1D" : ["delayed", "not approved", "rejected", "not completed"].includes(lowerCaseStatus) ? "#EE4424" : "#3FB5E1",
              }}></span>{" "}
            <p style={{ margin: "auto" }}>{status}</p>
          </div>
          {createdDate && (
            <div>
              <p>Start Date</p> <p>{createdDate}</p>
            </div>
          )}
          {end_date && (
            <div>
              <p>Due On</p> <p>{end_date}</p>
            </div>
          )}
          <div className="owner-assignee">
            Assigned To{" "}
            {imageAssigned ? (
              <div
                className="msg-img"
                style={{
                  background: `url(${imageAssigned})`,
                  backgroundSize: "contain",
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                }}></div>
            ) : (
              <i className="fad fa-user owner-assignee-dp"></i>
            )}
          </div>
          <div className="owner-assignee">
            Owner{" "}
            {imageOwner != null ? (
              <div
                className="msg-img"
                style={{
                  background: `url(${imageOwner})`,
                  backgroundSize: "contain",
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                }}></div>
            ) : (
              <i className="fad fa-user owner-assignee-dp"></i>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
