const components = [
    {
      "input": true,
      "tableView": true,
      "label": "Email",
      "key": "email",
      "placeholder": "Enter your email address",
      "type": "email"
    }
  ];
  export default components;
  