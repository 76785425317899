import React from "react";

import "./OL.scss";

const OL = ({ title, elementsList, id }) => {
  return (
    <div id={id} className="StylesGuideOl">
      <span>{title}</span>
      <ol>
        {elementsList?.map((element) => (
          <li key={element}>{element}</li>
        ))}
      </ol>
    </div>
  );
};

export default OL;
