import Swal from "sweetalert2";
import PageNavigation from "../PageNavigation";
import helpers from "../../helpers";

export const DeleteFile = async (api, item, urlPostParams, props) => {
  const restClient = props.core ? props.core.make("oxzion/restClient") : props.osjsCore.make("oxzion/restClient");
  let response = await restClient.request("v1", "/" + api, urlPostParams ? urlPostParams : {}, item.typeOfRequest ? item.typeOfRequest : "post");
  return response;
};

const updateActionHandler = (details, rowData, props, loader) => {
  rowData = { ...props.parentData, ...rowData };
  return new Promise((resolve) => {
    var queryRoute = helpers.ParameterHandler.replaceParams(props.appId, details?.params?.url ? details.params.url : details.route, rowData);
    var postData = {};
    try {
      if (details.params.postData) {
        Object.keys(details.params.postData).map((i) => {
          postData[i] = helpers.ParameterHandler.replaceParams(props.appId, details.params.postData[i], rowData);
        });
      } else {
        Object.keys(details.params).map((i) => {
          postData[i] = helpers.ParameterHandler.replaceParams(props.appId, details.params[i], rowData);
        });
        postData = rowData;
      }
    } catch (error) {
      postData = rowData;
    }
    helpers.ParameterHandler.updateCall(
      props.core,
      props.appId,
      queryRoute,
      postData,
      details?.params?.disableAppId ? details?.params?.disableAppId : true,
      details.method ? details.method : details.typeOfRequest,
    )
      .then((response) => {
        if (details?.params?.downloadFile && response.status == 200) {
          helpers.ParameterHandler.downloadFile(response).then((result) => {
            loader.destroy();
            var downloadStatus = result ? "success" : "failed";
            resolve({ status: downloadStatus });
          });
        } else {
          loader.destroy();
          resolve(response);
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Unexpected Error",
        });
        loader.destroy();
      });
  });
};

const responseHandler = (response, item, loader) => {
  if (response.status == "success") {
    loader.destroy();
    Swal.fire({
      icon: "success",
      title: item?.successMessage || item?.params?.successNotification || response?.message,
      showConfirmButton: true,
    });
    refreshHandler();
  } else {
    loader.destroy();
    Swal.fire({
      icon: "error",
      title: item?.errorMessage ? item.errorMessage : response.errors ? response.errors[0].message : response.message,
      showConfirmButton: true,
    });
    return false;
  }
};

const refreshHandler = () => {
  ["handleGridRefresh", "handleOIRefresh"].map((refresh) => {
    let ev = new CustomEvent(refresh, {
      detail: { hideLoader: true },
      bubbles: true,
    });
    if (document.getElementById("navigation_" + props.appId)) {
      document.getElementById("navigation_" + props.appId).dispatchEvent(ev);
    }
  });
};

export const buttonAction = async (actionCopy, rowData, props) => {
  const loader = props.core.make("oxzion/splash");
  const restClient = props.core ? props.core.make("oxzion/restClient") : props.osjsCore.make("oxzion/restClient");
  var action = actionCopy;
  if (action.content) {
    action.details = action.content;
  }
  //renaming the file_uuid to uuid cause that is how the data should be passed
  if (rowData["file_uuid"]) {
    rowData["uuid"] = rowData["file_uuid"];
    delete rowData["file_uuid"];
  }
  var mergeRowData = props.currentRow ? { ...props.currentRow, ...rowData } : rowData;
  if (action.page_id) {
    PageNavigation.loadPage(props.appId, props.pageId, action.page_id, null, null, null, null, null, null, action.popupConfig, rowData);
  } else if (action.details) {
    // var pageDetails = this.state.pageContent;

    var copyPageContent = [];
    if (rowData.rygRule) {
      copyPageContent.push({
        type: "HTMLViewer",
        content: rowData.rygRule,
        className: "rygBadge",
      });
    }
    var checkForTypeUpdate = false;
    // var updateBreadcrumb = true;
    var pageId = null;
    if (action.details.length > 0) {
      action.details.every(async (item, index) => {
        if (item.type == "Update") {
          var PageRenderDiv = document.getElementById(props.parentDiv);
          loader.show(PageRenderDiv ? PageRenderDiv : null, item.loaderMessage);
          checkForTypeUpdate = true;
          if (item.method == "DELETE") {
            Swal.fire({
              title: "Are you sure?",
              text: "Do you really want to delete the record? This cannot be undone.",
              icon: "question",
              imageWidth: 75,
              imageHeight: 75,
              confirmButtonText: "Delete",
              confirmButtonColor: "#d33",
              showCancelButton: true,
              cancelButtonColor: "#3085d6",
            }).then(async (result) => {
              if (result.value) {
                const response = await updateActionHandler(item, mergeRowData, props, loader);
                responseHandler(response, item, loader);
              }
            });
          } else {
            const response = await updateActionHandler(item, mergeRowData, props, loader);
            responseHandler(response, item, loader);
          }
        } else if (item.type == "API") {
          if (item.typeOfRequest == "delete") {
            action.updateOnly = true;
            var url = helpers.ParameterHandler.replaceParams(props.appId, item.route, mergeRowData);
            DeleteFile("app/" + props.appId + "/" + url, item, null, props).then((response) => {
              responseHandler?.(response, item, loader);
              if (response.status == "success") {
                props.notif.current.notify("Success", "Deleted Successfully", "success");
              } else {
                props.notif.current.notify("Error", item.errorMessage ? item.errorMessage : response.message, "danger");
              }
            });
          } else if (item.typeOfRequest == "post") {
            action.updateOnly = true;
            var url = helpers.ParameterHandler.replaceParams(props.appId, item.route, mergeRowData);
            var params = helpers.ParameterHandler.replaceParams(props.appId, item.params, mergeRowData);
            var postData = { ...mergeRowData, ...params };
            restClient
              .request("v1", "/" + url, postData, "post", {
                "Content-Type": "application/json",
              })
              .then((response1) => {
                if (response1.status == "success") {
                  if (item.successNotification) {
                    props.notif.current.notify(item.successNotification, "", "OK", false);
                  }
                  responseHandler?.(response1, item, loader);
                } else {
                  props.notif.current.notify(response1.message, "", "OK", false);
                }
              })
              .catch((err) => {
                props.notif.current.notify("Error", err.message || "Could not process request", "danger");
                console.error(err);
              });
          } else {
            const response = await updateActionHandler(item, mergeRowData, props, loader);
            responseHandler(response, item, loader);
            loader.destroy();
          }
        } else if (item.type == "Disabled") {
          action.updateOnly = true;
        } else if (item.type == "Custom") {
          action.updateOnly = true;
          var _Swal = Swal;
          var _moment = moment;
          var customAction = item.customAction.replace(/Swal/g, "_Swal").replace(/moment/g, "_moment");
          eval(customAction);
        } else if (item.type == "APIRequest") {
          action.updateOnly = true;
          var urlPostParams = {};
          if (item.params && item.params.urlPostParams) {
            urlPostParams = helpers.ParameterHandler.replaceParams(props.appId, item.params.urlPostParams, mergeRowData);
          }
          var url = helpers.ParameterHandler.replaceParams(props.appId, item.route, mergeRowData);
          Swal.fire({
            title: "Are you sure?",
            text: "Do you really want to delete the record? This cannot be undone.",
            // imageUrl:
            //   "https://image.flaticon.com/icons/svg/1632/1632714.svg",
            icon: "question",
            imageWidth: 75,
            imageHeight: 75,
            confirmButtonText: "Delete",
            confirmButtonColor: "#d33",
            showCancelButton: true,
            cancelButtonColor: "#3085d6",
          }).then((result) => {
            if (result.value) {
              DeleteFile("app/" + props.appId + "/" + url, item, urlPostParams, props).then((response) => {
                refreshHandler?.(response);
                if (response.status == "success") {
                  props.notif.current.notify("Success", "Deleted Successfully", "success");
                } else {
                  props.notif.current.notify("Error", response.message, "danger");
                }
              });
            }
          });
        } else {
          if (item.params && item.params.page_id) {
            pageId = item.params.page_id;
            if (item.params.params && typeof item.params.params === "string") {
              var newParams = helpers.ParameterHandler.replaceParams(props.appId, item.params.params, mergeRowData);
              mergeRowData = { ...newParams, ...mergeRowData };
            } else if (item.params.params && typeof item.params.params === "object") {
              var params = {};
              Object.keys(item.params.params).map((i) => {
                params[i] = helpers.ParameterHandler.replaceParams(props.appId, item.params.params[i], mergeRowData);
              });
              mergeRowData = { ...params, ...mergeRowData };
            }
            copyPageContent = [];
          } else if (item.type == "Event") {
            let ev = new CustomEvent(item.eventName, {
              detail: mergeRowData,
              bubbles: true,
            });
            document.getElementsByClassName(`Window_${props.appName}`)[0].dispatchEvent(ev);
          } else {
            var pageContentObj = {};
            mergeRowData = { ...props.parentData, ...mergeRowData };
            pageContentObj = helpers.ParameterHandler.replaceParams(props.appId, item, mergeRowData);
            copyPageContent.push(pageContentObj);
          }
        }
      });
      action.updateOnly
        ? null
        : PageNavigation.loadPage(
            props.appId,
            props.pageId,
            pageId,
            action.icon,
            true,
            action.name,
            mergeRowData,
            copyPageContent,
            undefined,
            action.popupConfig,
            mergeRowData,
          );
    }
  }
};
