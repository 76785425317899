const components = [
    {
      "label": "Status",
      "widget": "choicesjs",
      "placeholder": "Select the status",
      "tabindex": "2",
      "tableView": true,
      "defaultValue": "Open",
      "data": {
          "values": [{
              "value": "Completed",
              "label": "Completed"
          }, {
              "label": "Delayed",
              "value": "Delayed"
          }, {
              "label": "In Progress",
              "value": "In Progress"
          }, {
              "label": "Open",
              "value": "Open"
          }],
          "json": "",
          "url": "",
          "resource": "",
          "custom": ""
      },
      "key": "status",
      "conditional": {
          "eq": "In Progress",
          "show": null,
          "when": null
      },
      "type": "select",
      "labelWidth": 7,
      "input": true,
      "lockKey": true,
      "hideOnChildrenHidden": false,
      "prefix": "",
      "customClass": "",
      "suffix": "",
      "multiple": false,
      "protected": false,
      "unique": false,
      "persistent": true,
      "hidden": false,
      "clearOnHide": true,
      "refreshOn": "",
      "redrawOn": "",
      "modalEdit": false,
      "dataGridLabel": false,
      "labelPosition": "top",
      "description": "",
      "errorLabel": "",
      "tooltip": "",
      "hideLabel": false,
      "disabled": false,
      "autofocus": false,
      "dbIndex": false,
      "customDefaultValue": "",
      "calculateValue": "",
      "calculateServer": false,
      "attributes": {},
      "validateOn": "change",
      "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false,
          "onlyAvailableItems": false
      },
      "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
      },
      "allowCalculateOverride": false,
      "encrypted": false,
      "showCharCount": false,
      "showWordCount": false,
      "properties": {},
      "allowMultipleMasks": false,
      "idPath": "id",
      "clearOnRefresh": false,
      "limit": 100,
      "dataSrc": "values",
      "valueProperty": "",
      "lazyLoad": true,
      "filter": "",
      "searchEnabled": true,
      "searchField": "",
      "minSearch": 0,
      "readOnlyValue": false,
      "authenticate": false,
      "ignoreCache": false,
      "template": "<span>{{ item.label }}</span>",
      "selectFields": "",
      "selectThreshold": 0.3,
      "uniqueOptions": false,
      "fuseOptions": {
          "include": "score",
          "threshold": 0.3
      },
      "indexeddb": {
          "filter": {}
      },
      "customOptions": {},
      "useExactSearch": false,
      "id": "eemcj"
  }
  ];
  export default components;
  